
.multi-select-wrapper .searchWrapper {
    min-height: 50px;
    position: relative;
    border: var(--border-black-2);
    font-size: var(--font-size-l);
    background: var(--bg-white);
    color: var(--font-black);
    border-radius: 10px;
    margin-top: 5px;
    padding: 5px 50px 5px 10px;
}

.multi-select-wrapper .searchWrapper input {
    width: 40%;
    padding: 10px 0px 10px 0px;
}

.multi-select-wrapper .searchWrapper .chip {
    white-space: inherit;
    background: var(--bg-orange);
    padding: 10px 10px;
    font-size: 16px;
}

.multi-select-wrapper .optionContainer .highlightOption {
    background: var(--bg-orange);
}

.multi-select-wrapper .optionContainer li:hover {
    background: var(--bg-orange);
}