.trade-info .stock-name {
	display: flex;
	padding: 20px 0px;
}

.trade-info {
	font-family: var(--font-family-roboto);
}

.trade-info .stock-icon {
	margin-right: 5px;
}

.trade-info .stock-name span label:last-child {
	opacity: 0.4;
	font-size: var(--font-size-13);
	text-overflow: ellipsis;
	white-space: nowrap;
	display: block;
	overflow: hidden;
}

.trade-info .stock-desc {
	border-top: 2px var(--bg-grey3) dotted;
	padding: 10px 5px;
	border-bottom: 2px var(--bg-grey3) dotted;
	font-size: var(--font-size-s);
	line-height: 25px
}

.trade-info .view-less {
	max-width: 200ch;
	text-overflow:ellipsis;
	overflow:hidden;
	display: -webkit-box !important;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	white-space: normal;
}

.trade-info .link-text {
	font-size: var(--font-size-s);
}

.trade-info .stock-desc-head {
	opacity: 0.4;
}

.trade-info .stock-detail {
	display: flex;
	flex-wrap: wrap;
	padding: 5px 0px;
	border-bottom: 2px var(--bg-grey3) dotted;
}

.trade-info .stock-detail div {
	width: calc(50% - 10px);
	padding: 10px 5px;
	font-size: var(--font-size-s);
}

.trade-info .stock-detail div label {
	opacity: 0.4;
}

.trade-info .stock-detail div span {
	display: block;
	line-height: 22px;
	text-transform: uppercase;
}

.trade-info .stock-contact {
	font-size: var(--font-size-s);
	padding: 15px 5px;
	line-height: 22px;
	width: 165px;
}