.account-metrics {
	display: flex;
	justify-content: space-between;
	padding: 20px;
	margin-top: 20px;
}

.account-metrics .metrics-item {
	flex: 1;
	padding: 0px 5px;
	text-align: center;
}

.account-metrics .metrics-item label,
.account-metrics .metrics-item div span:nth-child(2) {
	font-size: var(--font-size-15);
	color: var(--font-grey);
	white-space: nowrap;
}

.account-metrics .metrics-item div {
	font-size: var(--font-size-xl);
	padding: 10px 0px;
	color: var(--font-black);
	font-weight: var(--font-weight-600);
	text-align: center;
	width: 100%;
	margin-bottom: 15px;
}
.account-metrics .metrics-item div span {
	display: block;
}
.account-metrics .metrics-item .green-value {
	color: var(--font-green);
}
.account-metrics .metrics-item .red-value {
	color: var(--font-red);
}

@media screen and (min-width: 100px) and (max-width: 1020px) {

	.view-container{
		margin-right: 0px;
	}
	
}
@media screen and (min-width: 100px) and (max-width: 676px) {
	.account-metrics{
		padding: 20px 5px;
	}
	.account-metrics .metrics-item div{
		font-size: var(--font-size-11);
	}

	.account-metrics .metrics-item label{
		font-size: 8px;
	}

	.account-metrics .metrics-item div span:nth-child(2) {
		font-size: var(--font-size-s);
	}
	

	body .wrapper-container{padding:0px 5px}
	
}