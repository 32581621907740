.news-datetime-container {
	display: flex;
	justify-content: space-between;
	padding: 20px 0px 0px 0px;


}

.news-datetime-container .card-tools img {
	width: 24px;
}

.news-datetime {
	font-size: var(--font-size-m);
}

.news-company-info {
	font-size: var(--font-size-m);
	font-family: var(--font-family-roboto);
}

.news-dialog-link a {
	margin-left: 10px;
}

.news-link-info {
	color: var(--font-orange);
	font-size: var(--font-size-m);
	font-weight: var(--font-weight-600);
	outline: none;
}

.news-detail-item {
	margin-top: 30px;
}

.news-dialog-info {
	line-height: 25px;
	text-align: justify;
}

.like-share-container {
	display: flex;
	justify-content: right;
	padding: 20px 10px;
}

.like-share-container span {
	margin-left: 20px;
	font-weight: var(--font-weight-600);
}

.like-share-container span img {
	width: 20px;
	margin-left: 5px;
	position: relative;
	top: 4px;
	cursor: pointer;
}

.news-dialog-btn-container {
	display: flex;
	margin-bottom: 20px;
}

.news-dialog-btn-container a {
	color: var(--font-white);
	font-weight: var(--font-weight-600);
	background: var(--bg-dark-grey);
	padding: 10px 25px;
	margin-right: 15px;
	border-radius: 10px;
}

.news-dialog-btn-container a:hover {
	background: var(--bg-orange);
}
.modal-root.news-dialog{
	max-width: 980px;
}
.modal-root.news-dialog .modal-body{
	max-height: 84vh;
	
}
.news-dialog .news-detail-item img{
	width: 100%;

}
@media screen and (min-width: 100px) and (max-width: 676px) {
	.news-dialog-link {
		display: flex;
		flex-wrap: wrap;
	}

	.news-datetime {
		font-size: var(--font-size-xs);
	}

	.news-link-info {
		font-size: var(--font-size-11);
	}

	.news-company-info {
		font-size: var(--font-size-xs);
	}

	.news-datetime-container .card-tools img {
		width: 20px;
		position: relative;
		top: -2px;
	}

	.news-dialog-btn-container a {
		font-size: var(--font-size-11);
		padding: 5px 10px;
		margin-right: 5px;
	}

}