.confirm-modal-content {
    height: 50px;
    padding: 15px 0px;
    color: var(--font-black);
    font-size: var(--font-size-m);
    font-family: var(--font-family-roboto);
}

.margin-10 {
    margin: 10px;
}