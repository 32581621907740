.chart-type-item {
	display: flex;
}

.chart-type-item div {
	margin-right: 15px;
}

.chart-type-item div label {
	color: var(--font-grey3);
	font-size: var(--font-size-m);
}

.chart-type-item div select {
	border: none;
	outline: none;
	font-size: var(--font-size-m);
	margin-left: 2px;
	height: 30px;
}

@media screen and (min-width: 100px) and (max-width: 676px) {
	.chart-type-item div label {
		font-size: var(--font-size-xs);
	}

	.chart-type-item div select {
		font-size: var(--font-size-xs);
	}
}