.modal-root .doted-border{
    background-image: linear-gradient(to right, #2e2e2e 26%, #fff0 2%);
    background-position: bottom;
    background-repeat: repeat-x;
    background-size: 7px 1px;
    border: none;
    padding-bottom: 1px;
}
.doted-border .modal-header-text{
    color: var(--font-black);
}
.doted-border .modal-header-text img {
    margin-right: 0px;
    top: 11px;
}
.order-datetime {
    font-size: var(--font-size-s);
    color: var(--font-grey);
    padding-top: 15px;
}
.order-detail {
    display: flex;
    padding: 20px 0px;
    flex-wrap: wrap;
}
.order-detail .detail-item {
    width: calc(33% - 10px);
    padding: 20px 5px;
}
.order-detail .detail-item label {
    font-size: var(--font-size-s);
    color: var(--font-grey);
    display: block;
    position: relative;
    font-weight: normal;
    margin-bottom: 10px;
}
.order-detail .detail-item div {
    font-size: var(--font-size-l);
    padding-top: 5px;
    color: var(--font-black);
    font-weight: var(--font-weight-700);
}
.order-detail .order-info span:first-child {
   margin-right: 5px;
    position: relative;
    top: -6px;
}
.order-detail .order-info{
    display: flex;
}
.order-detail .order-info span:last-child {
    display: flex;
    flex-direction: column;
}
.order-detail .order-info .order-info-icon label:first-child {
    font-size: var(--font-size-l);
    color: var(--font-black);
    font-weight: var(--font-weight-700);
}
@media screen and (min-width: 100px) and (max-width: 676px) {
    .order-detail .detail-item label{
        font-size: var(--font-size-9);
        margin-bottom: 0;
   }
    .modal-body .account-details .detail-item label{
        font-size: var(--font-size-9);
        white-space: nowrap;
   }
    .order-detail .order-info span.order-info-icon{
        display: initial;
   }
    .order-detail .order-info .order-info-icon label:first-child {
        font-size: var(--font-size-s);
   }
    .order-detail .detail-item div{
        font-size: var(--font-size-s);
   }
    .modal-body .account-details .detail-item {
        width: calc(33% - 4px);
        padding: 20px 2px;
   }
}
