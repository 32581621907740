.highcharts-popup.highcharts-annotation-toolbar {
  right: 10%;
  left: auto;
  height: 40px;
  overflow: hidden;
  padding-right: 40px;
  width: auto;
  min-width: 0;
}

.highcharts-popup.highcharts-annotation-toolbar button {
  margin-top: 0px;
}

.highcharts-popup.highcharts-annotation-toolbar > span {
  display: block;
  float: left;
  padding: 12px;
}

.highcharts-popup {
  font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;
  background-color: #fff;
  color: #666666;
  display: none;
  font-size: 0.876em;
  max-height: 90%;
  top: 5%;
  left: 15%;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 75%;
  min-width: 300px;
  max-width: 600px;
  position: absolute;
  z-index: 100;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(61, 61, 61, 0.3);
  -moz-box-shadow: 0px 0px 8px 0px rgba(61, 61, 61, 0.3);
  box-shadow: 0px 0px 8px 0px rgba(61, 61, 61, 0.3);
}

.highcharts-popup div, .highcharts-popup span {
  box-sizing: border-box;
}

.highcharts-popup input, .highcharts-popup label, .highcharts-popup select {
  clear: both;
  float: left;
  width: 100%;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.highcharts-popup input {
  border: 1px solid #e6e6e6;
  border-radius: 0.3rem;
  padding: 5px;
  width: 100%;
}

.highcharts-popup-lhs-col, .highcharts-popup-rhs-col {
  height: 100%;
  float: left;
  overflow-y: auto;
}

.highcharts-popup-lhs-col.highcharts-popup-lhs-full {
  width: 100%;
  overflow-x: hidden;
  height: calc(100% - 104px);
  border: none;
  padding: 20px;
  padding-bottom: 10px;
}

.highcharts-popup-lhs-col.highcharts-popup-lhs-full + .highcharts-popup-bottom-row {
  width: 100%;
}

.highcharts-popup-lhs-col {
  clear: both;
  width: 30%;
  border-right: 1px solid #e6e6e6;
}

.highcharts-popup-bottom-row {
  float: left;
  padding: 0px 20px;
  padding-bottom: 12px;
  width: 100%;
  border-top: 1px solid #e6e6e6;
}

.highcharts-popup-rhs-col {
  width: 70%;
  height: calc(100% - 40px);
  padding: 20px;
}

.highcharts-popup-rhs-col-wrapper {
  width: 100%;
  overflow-x: hidden;
}

.highcharts-popup-rhs-col-wrapper h3 {
  margin-top: 0px;
  padding-bottom: 0px;
}

.highcharts-bindings-wrapper ul.highcharts-indicator-list,
.highcharts-indicator-list {
  float: left;
  color: #666666;
  height: calc(100% - 150px);
  width: 100%;
  overflow-x: hidden;
  margin: 0px;
  padding: 15px 0px;
}

.highcharts-indicator-list li {
  cursor: pointer;
  padding: 5px 20px;
  margin: 0px;
  width: 100%;
  height: auto;
  overflow: hidden;
  word-break: break-all;
  box-sizing: border-box;
}

.highcharts-indicator-list li:hover {
  background-color: #e6e6e6;
}

.highcharts-tab-item {
  background-color: #f7f7f7;
  cursor: pointer;
  display: block;
  float: left;
  padding: 0px 10px;
  height: 40px;
  line-height: 40px;
}

.highcharts-tab-item.highcharts-tab-item-active {
  background-color: #e6e6e6;
}

.highcharts-tab-item-content {
  display: none;
  float: left;
  height: 100%;
  overflow: hidden;
  width: 100%;
  border-top: 1px solid #e6e6e6;
}

.highcharts-tab-item-show {
  display: block;
}

.highcharts-popup-close {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: absolute;
  padding: 10px;
  top: 0%;
  right: 0%;
  color: #333333;
}

.highcharts-popup-close:hover,
.highcharts-popup button:hover,
.highcharts-popup button.highcharts-annotation-edit-button:hover,
.highcharts-popup button.highcharts-annotation-remove-button:hover {
  background-color: #e6e6e6;
  cursor: pointer;
}

.highcharts-popup button {
  float: right;
  border: none;
  background: #f7f7f7;
  color: #666666;
  margin-left: 5px;
  margin-top: 12px;
}

.highcharts-popup button:first-child {
  margin-left: 0;
}

.highcharts-tab-disabled {
  color: #cccccc;
}

/* annotation edit small popup */
.highcharts-popup button.highcharts-annotation-edit-button,
.highcharts-popup button.highcharts-annotation-remove-button {
  width: 20px;
  height: 40px;
  padding: 20px;
}

.highcharts-popup button.highcharts-annotation-edit-button {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  text-indent: -9999px;
}

.highcharts-popup button.highcharts-annotation-remove-button {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  text-indent: -9999px;
}

.highcharts-popup .highcharts-annotation-title {
  display: block;
  float: left;
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 15px;
  width: 100%;
}

.highcharts-popup .highcharts-popup-main-title {
  border-bottom: 1px solid #e6e6e6;
  margin: 0px;
  padding: 8px 0px 6px 20px;
}

.highcharts-indicator-title {
  float: left;
  padding-bottom: 15px;
}

.highcharts-input-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
}

.highcharts-input-search-indicators-label {
  text-align: center;
  font-weight: bold;
  color: #000000;
  margin-top: 0.5rem;
}

input.highcharts-input-search-indicators {
  width: 80%;
  margin: 0 auto;
  float: none;
  border-color: #949494;
}

.highcharts-popup a.clear-filter-button {
  margin: 0 auto;
  display: none;
  color: #335cad;
  background-color: #fff;
  border: 1px solid #335cad;
  margin-top: 10px;
}

.highcharts-popup a.clear-filter-button:hover {
  color: #fff;
  background-color: #335cad;
  cursor: pointer;
}