div[modal-size='medium'] {
	max-width: 688px;
	width: 98%;
	font-family:var(--robotoregular) ;
}

div[modal-size='small'] {
	max-width: 450px;
	width: 75%;
	font-family:var(--robotoregular) ;
}

div[modal-size='large'] {
	max-width: 940px;
	width: 85%;
	font-family:var(--robotoregular) ;
}

.modal-body {
	max-height: 70vh;
	overflow: auto;
}

.modal-overlay {
	z-index: 2;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.4);
}

.modal-root {
	z-index: 3;
	position: fixed;
	background: var(--bg-white);
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 20px;
	box-shadow: 0 2px 8px #636363e0;
	padding: 5px 20px;
	border: var(--border-grey-1);
}

.modal-header-text {
	font-size: var(--font-size-xxl);
	font-weight: var(--font-weight-600);
	margin: 10px 0px 14px 0px;
	color: var(--font-black);
	
	
}

.modal-header-text img {
	position: relative;
	top: 6px;
	margin-right: 10px;
	width: 29px;
}


/* Add Animation */

@-webkit-keyframes animatetop {
	from {
		top: -300px;
		opacity: 0
	}
	to {
		top: 0;
		opacity: 1
	}
}

@keyframes animatetop {
	from {
		top: -300px;
		opacity: 0
	}
	to {
		top: 0;
		opacity: 1
	}
}


/* The Close Button */

.modal-close {
	position: absolute;
	top: 15px;
	right: 20px;
	float: right;
	font-size: var(--font-size-36);
	font-weight: var(--font-weight-400);
	color: var(--font-grey);
	text-decoration: none;
	cursor: pointer;
}

.modal-close:hover {
	color: #000;
}

.modal-root header {
	border-bottom: var(--border-black-1);
	margin-right: 25px;
}

.modal-footer {
	padding: 30px 0px;
	text-align: center;
}
@media screen and (min-width: 100px) and (max-width: 840px) {
	div[modal-size='medium'] {
		width: 90%;
		padding: 10px 5px;
	}
}

@media screen and (min-width: 100px) and (max-width: 676px) {
	.modal-header-text {
		font-size: var(--font-size-m);
	}
}
