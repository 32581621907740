/*-------- Start of Footer css--------*/

.footer {
	margin: 0px;
	width: 100%;
	padding: 20px 0px 0px 0px;
	background: var(--bg-light-grey);
	box-shadow: 0px -4px 10px #0000001a;
}

.footer-inner {
	margin: auto;
	display: flex;
	padding: 20px 40px;
}

.footer-col {
	flex: 1;
}

.footer-header {
	color: var(--font-orange);
	font-size: var(--font-size-m);
	font-style: normal;
	font-weight: var(--font-weight-700);
	padding-bottom: 20px;
}

.footer-menu {
	margin: 0px;
	padding: 0;
}

.footer-menu ul {
	margin: 0px;
	padding: 0px;
}

.footer-menu ul li {
	list-style: none;
	margin-top: 15px;
}

.footer-menu ul li a {
	padding: 2px 0px;
	color: var(--font-black);
	text-decoration: none;
	font-size: var(--font-size-m);
}

.footer-menu ul li a:hover {
	color: var(--font-orange);
}

.footer-address {
	margin: 0;
}

.footer-address ul {
	margin: 0px;
	padding: 0;
}

.footer-address ul li {
	list-style: none;
	margin-top: 15px;
	color: var(--font-black);
	font-size: var(--font-size-m);
}

.footer-contact {
	margin: 0;
	padding-top: 30px;
}

.footer-contact ul {
	padding: 0;
	margin: 0;
}

.footer-contact ul li {
	list-style: none;
	margin-top: 15px;
}

.footer-contact ul li a {
	text-decoration: none;
	color: var(--font-black);
	font-size: var(--font-size-m);
}

.footer-contact ul li a img {
	width: 16px;
	margin-right: 10px;
}

.footer-about {
	width: 320px;
	font-size: var(--font-size-m);
	padding: 30px 0px 10px 0px;
	line-height: 28px;
}
.footer-star {
	font-size: var(--font-size-s);
}

.social-icon {
	margin: 5px 0;
}

.social-icon ul {
	margin: 0px;
	padding: 0px;
	display: flex;
}

.social-icon ul li {
	list-style: none;
	margin-right: 10px;
}

.social-icon ul li img {
	width: 25px;
}

.copyright {
	padding: 20px 0;
	text-align: center;
	font-size: var(--font-size-l);
	color: var(--font-black);
	background: var(--bg-light-grey3);
	margin-top: 50px;
}

@media screen and (min-width: 100px) and (max-width: 840px) {
	.footer-header {
		font-size: var(--font-size-s);
		padding-bottom: 10px;
	}
	.footer-address ul li {
		font-size: var(--font-size-xs);
	}
	.footer-menu ul li a{
		font-size: var(--font-size-xs);
	}

.footer-inner {
	width: calc(100% - 80px);
	flex-direction: row;
	flex-wrap: wrap;
}

.footer-inner .footer-col.first-col {
	width: 100%;
	flex: none;
	padding-bottom: 10px;
}
.footer-contact ul li a{
	font-size: var(--font-size-xs);
}
.footer-contact{
	padding-top: 5px;
}

.footer-inner .footer-col.second-col {
	width: 33%;
	flex: none;
}

.footer-about {
	width: 100%;
	font-size: var(--font-size-xs);
	padding: 15px 0 10px;
    line-height: 20px;
}

.footer-col {
	width: 49%;
}

.copyright {
	padding: 10px 0;
	font-size: var(--font-size-13);
	margin-top: 20px;
}

.footer-inner {
	width: calc(100% - 40px);
	padding: 10px 20px;
}


}

/*-------- End of Footer css--------*/