body {
	margin: 0px;
	padding: 0px;
	font-size: var(--font-size-s);
	font-family: var(--font-family-inter);
	background: var(--bg-white);
	color: var(--font-black);
}

.header-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 20px;
}


.header-errors{
	color:red;
	font-family: var(--font-family-roboto);
	font-size: var(--font-size-l);
	font-weight: 100;
}

.wrapper-container {
	padding: 0px 20px;
	margin-top: 115px;
}

::-webkit-input-placeholder {
	color: var(--font-grey3);
}

:-ms-input-placeholder {
	color: var(--font-grey3);
}

::placeholder {
	color: var(--font-grey3);
	font-weight: normal;
	font-size: var(--font-size-l);
	font-family: var(--font-family-inter);

}

h1 {
	font-family: var(--font-family-roboto);
	font-size: var(--font-size-36);
	font-weight: 100;

}


.padding-left-zero {
	padding-left: 0px !important;
}

.padding-top-20 {
	padding-top: 20px;
}

.padding-bttom-10 {
	padding-bottom: 10px;
}

.margin-top-30 {
	margin-top: 30px;
}

.margin-top-20 {
	margin-top: 20px;
}

.margin-bottom-30 {
	margin-bottom: 30px;
}

.margin-right-30 {
	margin-right: 30px;
}

.padding-right-15 {
	padding-right: 15px;
}

.padding-20 {
	padding: 20px;
}

.padding-0 {
	padding: 0px;
}

.padding-top-0 {
	padding-top: 0px;
}

.button-sm {
	align-items: center;
	border-radius: 50px;
	font-weight: 600;
	font-size: var(--font-size-s);
	font-family: var(--font-family-rubik);
	cursor: pointer;
	padding: 15px 30px;
	letter-spacing: 1px;
	text-transform: uppercase;
	border: 0px;
	outline: none;
}

.btn-orange {
	background: var(--bg-orange);
	color: var(--font-white);
}


.btn-orange-border {
	color: var(--font-orange);
	border: var(--border-orange-1);
	background: transparent;
}

.bg-green {
	width: 25px;
	height: 25px;
	border-radius: 100%;
	background: var(--bg-green);
	text-align: center;
}

.bg-grey {
	background: var(--bg-light-grey);
}

.blank-container {
	height: 250px;
	text-align: center;
	padding-top: 20px;
	clear: both;
}

.hide {
	display: none;
}

.link-text {
	color: var(--font-blue);
	text-decoration: underline;
	font-size: var(--font-size-l);
	font-weight: var(--font-weight-600);
	cursor: pointer;
}

.border-black-1 {
	border: var(--border-black-1);
}

.pagination select {
	border: var(--border-grey-1);
	background: var(--bg-white);
	color: var(--font-black);
	border-radius: 5px;
	margin-top: 5px;
	padding: 8px 5px;
	width: 90px;
	outline: none;
}

.search-box-item {
	flex: 1;
	padding: 0px 5px;
	position: relative;
	z-index: 1;
}

.search-box-item img {
	position: absolute;
	right: 20px;
	bottom: 20px;
	width: 18px;
	cursor: pointer;
}

.search-box-item label {
	font-size: var(--font-size-s);
	color: var(--font-grey2);
	white-space: nowrap;
}

.search-box-item input[type=text] {
	width: calc(100% - 63px);
	border: var(--border-black-2);
	height: 43px;
	border-radius: 10px;
	padding: 5px 50px 5px 10px;
	margin-top: 5px;
	font-size: var(--font-size-l);
	background: var(--bg-white);
	color: var(--font-black);
}

.border-grey input[type="text"] {
	border: var(--border-grey-1);
	background: var(--bg-white);
	outline: none;
}


.grid-icon-info {
	display: flex;
}

.grid-icon-info .grid-icon {
	margin-right: 5px;
}

.grid-icon-info .grid-icon img {
	width: 22px;
}

.grid-icon-info span label:last-child,
.portfolio-name-column span label:not(:nth-child(1)) {
	opacity: .6;
	font-size: var(--font-size-13);
	text-overflow: ellipsis;
	padding-top: 5px;
	display: block;
	overflow: hidden;
}
.portfolio-name-column .grid-icon {
	margin-right: 5px;
	display: grid;
}
.select-in-header select {
	border: none;
	outline: none;
	font-size: var(--font-size-m);
	margin-left: 2px;
	height: 30px;
}

.multi-select-item {
	flex: 1;
	padding: 0px 5px;
	position: relative;
	z-index: 1;
}

.multi-select-item .multi-select-icon {
	position: absolute;
	right: 20px;
	bottom: 20px;
	width: 18px;
	cursor: pointer;
}

.multi-select-item .multi-select-label {
	font-size: var(--font-size-s);
	color: var(--font-grey2);
	white-space: nowrap;
}

.refresh-button {
	background: var(--bg-orange);
    border: var(--border-orange-1);
	height: 40px;
    cursor: pointer;
    color: var(--font-white);
    border-radius: 11px;
	padding: 0px 5px;
    position: relative;
}

.refresh-button:hover {
	background: var(--bg-white);
    color: var(--font-orange);
}

.refresh-button svg:hover {
	fill: var(--font-orange);
}


@media screen and (min-width:100px) and (max-width:676px) {
	.search-box-item input[type=text] {
		height: 35px;
		font-size: var(--font-size-m);

	}

	::placeholder {
		font-size: var(--font-size-s);
	}


	.grid-icon-info .grid-icon {
		margin-right: 2px;
	}

	.grid-icon-info span label:last-child {

		font-size: var(--font-size-9);

	}

	.search-box-item img {
		bottom: 13px;
	}

	.large-group-info {
		position: initial;
		font-size: var(--font-size-xs);
	}

	.large-group-info img {
		width: 15px;
	}

	h1 {
		font-size: var(--font-size-m);
	}

	.button-sm {
		padding: 10px 15px;
	}

	.button-sm {
		font-size: var(--font-size-xs);
	}

	.header-container-title {
		padding: 10px;

	}

	.card-main-container .card.no-padding-mobile {
		padding: 0px;
	}
	.select-in-header select,
	.select-in-header label {
		font-size: var(--font-size-xs);
	}
}

.float-right {
	float: right;
}

.float-left {
	float: left;
}

.clear {
	clear: both;
}

.text-center {
	text-align: center;
}

a {
	text-decoration: none;
}

.text-right {
	text-align: right;
}

.no-flex {
	flex: none;
}