.metrics-detail-dialog {
	padding: 10px 0px;
}

.metrics-detail-dialog .history-date label {
	font-size: var(--font-size-m);
	color: var(--font-black);

}

.metrics-detail-dialog .history-date img {
	position: relative;
	top: 4px;
}

.metrics-detail-dialog .auto-omplete-item {
	display: flex;
	padding: 10px 10px 20px 0px;
	justify-content: space-between
}

.metrics-detail-dialog .auto-omplete-info {
	display: flex;
	align-items: center;
}

.metrics-detail-dialog .auto-omplete-info span {
	color: var(--font-black);
	font-weight: var(--font-weight-600);
}

.metrics-detail-dialog .auto-omplete-info a {
	margin-left: 15px;
	text-decoration: underline;
	white-space: nowrap;
}

.metrics-detail-dialog .auto-omplete {
	position: relative;
}

.metrics-detail-dialog .auto-omplete input[type="text"] {
	width: calc(100% - 63px);
	border: var(--border-grey-1);
	height: 35px;
	font-size: var(--font-size-l);
	outline: none;
	border-radius: 10px;
	background:var(--bg-white);
	padding: 5px 50px 5px 10px;

}

.metrics-detail-dialog .auto-omplete img {
	width: 18px;
	position: absolute;
	top: 20px;
	right: 14px;
}

@media screen and (min-width: 100px) and (max-width: 676px) {

	.metrics-detail-dialog .auto-omplete-item {
		display: block;
	}

	.metrics-detail-dialog .auto-omplete-info span {
		font-size: var(--font-size-xs);
	}

	.metrics-detail-dialog .auto-omplete-info {
		padding-bottom: 10px;
	}

	.metrics-detail-dialog .history-date label {
		font-size: var(--font-size-xs);
	}

	.metrics-detail-dialog .history-date img {
		width: 18px;
	}
}