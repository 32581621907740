.border-dotted {
	border-bottom: 2px #999898 dotted;
	border-right: 2px #999898 dotted;
	min-height: 200px;
	width: calc(33% - 20px);
	padding: 10px 10px 40px 10px;
}

.border-dotted:nth-child(3n) {
	border-right: 0px;
}

.insights-container .insight-search-box {
	max-width: 400px;
	padding: 20px 5px;
}

.insights-container .trade-action .search-box-item input[type="text"] {
	border: var(--border-grey-1);
	outline: none;
	background: var(--bg-white);
	
}


.insights-container.sm-tab .tab ul.nav li {
	min-width: 25%;
}

.insights-info {
	font-size: var(--font-size-xl);
	color: var(--font-black);
	font-family: var(--font-family-inter);
	padding: 20px 5px 20px 5px;
	line-height: 32px;
}

.flex-wrap-container {
	display: flex;
	flex-wrap: wrap;
	padding: 0;
}

.insights-container .tab .outlet {
    border: 0px;
    padding-top: 20px;
}

.insights-container .tab ul.nav {
	border-bottom: var(--border-black-1);
}

.fullscreen-container {
	justify-content: space-between;
	padding: 5px 10px;
	margin-bottom: 10px;
	font-family: var(--font-family-roboto);
	display: flex;
}

.fullscreen-container span {
	font-size: var(--font-size-l);
	color: var(--font-black);
}

.gaph-here {
	height: 150px;
	background: #ccc;
}

.fullscreen-container img {
	width: 18px;
	cursor: pointer;
}

.fullscreen-icon img {
	width: 18px;
	height: 18px;
	cursor: pointer;
}

.insights-graph-info {
	line-height: 25px;
	font-size: var(--font-size-l);
	color: var(--font-black);
	font-weight: var(--font-weight-300);
	font-family: var(--font-family-inter);
	text-align: justify;
	padding: 10px 0px;
}

.insights-container .tab ul.nav li:first-child img {
	width: 19px;
	position: relative;
	left: 5px;
}

.insights-container .border-dotted .link-text {
	color: var(--font-black);
	font-size: var(--font-size-m);
	font-weight: var(--font-weight-300);
	margin-top: 10px;
	display: inherit;
}

.insights-list-info {
	display: flex;
}

.insights-list-col {
	flex: 1;
	padding-top: 20px;
}

.insights-list-col ul {
	padding: 0px;
	margin: 0px 0px 0px 20px;
	display: flex;
	flex-wrap: wrap;
}

.insights-list-col ul li {
	padding-bottom: 15px;
	color: var(--font-black);
	width: calc(49% - 15px);
	padding-right: 15px;
}

.news-item {
	display: flex;
	padding: 10px 0px;
	margin: 15px 0px 10px 0px;
}

.news-info {
	margin-left: 15px;
	margin-top: 5px;
}

.news-info label {
	font-size: var(--font-size-s);
	color: var(--font-grey);
	display: block;
}

.news-info a {
	font-size: var(--font-size-m);
	color: var(--font-black);
	padding-top: 10px;
	display: block;
	font-weight: var(--font-weight-600);
}

.news-info-status {
	display: flex;
	font-weight: var(--font-weight-600);
	margin-top: 10px;
}

.news-info-status img {
	width: 29px;
	margin-right: 10px;
}

.news-info-status div {
	padding-top: 5px;
}

.header-dotted {
	background-image: linear-gradient(to right, #2e2e2e 26%, #fff0 2%);
	background-position: bottom;
	background-repeat: repeat-x;
	background-size: 7px 1px;
	border: none;
	padding-bottom: 7px;
}

.graph-here {
	min-height: 150px
}

.news-large-img img {
	width: 100%;
}

.news-large {
	background-image: linear-gradient(to right, #2e2e2e 26%, #fff0 2%);
	background-position: bottom;
	background-repeat: repeat-x;
	background-size: 10px 1px;
	border: none;
	margin: 20px 0;
	padding-bottom: 30px;
}

.news-large .link-text {
	color: var(--font-orange);
	font-size: var(--font-size-m);
}

.news-large-img {
	margin-top: 20px;
}

.news-large-info {
	margin-top: 15px;
	line-height: 23px;
	color: var(--font-black);
}

.border-dotted2 .border-dotted {
	width: calc(49% - 20px);
	padding-top: 20px;
}

.border-dotted2 .border-dotted:nth-child(3n) {
	border-bottom: 2px dotted #999898;
	border-right: 2px dotted #999898;
}

.border-dotted2 .border-dotted:nth-child(2n) {
	border-right: 0px;
}

.news-small-container .card-header-v2 {
	background: none;
	border-bottom: var(--border-black-1);
	padding: 10px 0px;
}

.news-small-container .card-header-v2 .card-tools-icon {
	width: 18px;
	position: relative;
	top: 2px;
}

.news-small-container .card-header-v2 .card-tools-text {
	font-size: var(--font-size-xs);
	color: var(--font-blue);
}

.insights-container .news-small-container .link-text {
	color: var(--font-orange);
	font-weight: var(--font-weight-600);

}


@media screen and (min-width: 677px) and (max-width: 1024px) {
	.border-dotted {
		width: calc(49% - 20px);
	}

	.border-dotted:nth-child(3n) {
		border-right: 2px #ccc dotted;
	}

	.border-dotted:nth-child(2n) {
		border-right: 0px;
	}
}

@media screen and (min-width: 100px) and (max-width: 676px) {
	.news-large-info{
		font-size: var(--font-size-xs);
	}
	.insights-container .tab ul.nav li:first-child img {
		width: 14px;
	}
	.fullscreen-container span {
		font-size: var(--font-size-m);
		
	}
	.insights-info {
		font-size: var(--font-size-m);	
	}
	
	.insights-graph-info {
		font-size: var(--font-size-xs);
	}
	.flex-wrap-container {
		display: block;
	}

	.border-dotted2 .border-dotted:nth-child(3n) {
		border-bottom: 0px;
		border-right: 0px;
	}

	.border-dotted2 .border-dotted {
		padding-left: 0px;
	}


	.border-dotted2 .border-dotted {
		width: 100%;
	}


	.insights-list-col ul {
		display: block;
	}

	.insights-list-col ul li {
		width: 100%;
	}

	.news-item {
		display: block;
	}

	.border-dotted {
		width: calc(99% - 20px);
		border-right: 0px;
	}

	.insights-list-info {
		display: block;
	}

	.border-dotted:nth-child(3n) {
		border-right: 0px;
	}

	.insights-container.sm-tab .tab ul.nav li {
		min-width: 30%;
	}

	.border-dotted:nth-child(2n) {
		border-right: 0px;
	}

	.news-info {
		margin-left: 0;
	}

	.news-info span {
		font-size: var(--font-size-xs);
	}

	.news-info label {
		font-size: var(--font-size-11);
		color: var(--font-grey);
		display: block;
	}

	.news-info-status div {
		font-size: var(--font-size-xs);
	}

	.news-info-status img {
		width: 23px;
		margin-right: 5px;
	}
}