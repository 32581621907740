.dropdown-item {
	flex: 1;
	padding: 10px;
	position: relative;
}

.dropdown-item select {
    width: 33%;
    border: var(--border-grey-1);
    height: 54px;
    font-size: var( --font-size-l);
    color: var(--font-black);
    background: var(--bg-white);
    border-radius: 10px;
    margin-top: 5px;
    padding: 5px 10px;
    outline: none;
    font-weight: var(--font-weight-600);
}
