.pophover-chart-header{
	display: flex;
	justify-content: space-between;
	font-size: var(--font-size-m);
	font-weight: var(--font-weight-700);
	border-bottom: var(--border-black-1);
	padding-bottom: 6px;

}
.pophover-chart-header span:last-child{
	font-weight: normal;
	color: var(--font-grey2);
	font-family: var(--font-family-roboto-regular);
}
.pophover-chart-info{
	color: var(--font-grey2);
	font-family: var(--font-family-roboto-regular);
	padding-top: 10px;
}
.pophover-chart-footer {
	display: flex;
    padding-top: 10px;
    justify-content: end;

}
.pophover-chart-footer a {
	text-decoration: underline;
	color: var(--font-blue);
	margin-left: 10px;
	cursor: pointer;

}