/*-------- Start of Top NavBar css--------*/

.top-header {
	display: flex;
	margin: 0px;
	padding: 13px 20px;
	position: fixed;
	width: calc(100% - 40px);
	top: 0px;
	background: var(--bg-white);
	height: 70px;
	align-items: center;
	z-index: 2;
}

.top-header:after {
	content: '';
	height: 7px;
	width: 100%;
	background: var(--bg-orange);
	background: linear-gradient(90deg, rgba(255, 107, 0, 1) 0%, rgba(252, 216, 213, 1) 100%, rgba(9, 9, 121, 1) 100%);
	position: absolute;
	margin-top: 96px;
	left: 0px;
}

.top-navbar {
	margin: 0;
	flex: 1;
	padding-left: 25px;
}

.top-navbar ul {
	display: flex;
	margin: 0px;
	padding: 0;
	justify-content: right;
}

.top-navbar ul li {
	list-style: none;
	margin: 0px 20px;
	align-items: center;
	display: flex;
	align-items: center;
}

.top-navbar ul li a {
	color: var(--font-black);
	font-size: var(--font-size-xl);
	cursor: pointer;
	font-weight: var(--font-weight-400);
	padding: 0px;
	display: block;
	text-decoration: none;
}

.top-navbar ul li a:hover {
	color: var(--font-orange);
}

.menu-search_icon {
	height: 28px;
	cursor: pointer;
	width: 28px;
}

.menu-message {
	height: 44px;
	position: relative;
	width: 44px;
}

.message-icon {
	height: 35px;
	left: 0;
	position: absolute;
	top: 5px;
	width: 36px;
}

.message-icon-text {
	font-size: 13px;
	background: var(--bg-orange);
	border-radius: 100%;
	height: 18px;
	left: 27px;
	color: var(--font-white);
	min-width: 18px;
	text-align: center;
	line-height: 18px;
	position: absolute;
	top: -2px;
}

.toggle-switcher {
	display: none;
}

.toggle-switcher:checked+.toggle-switch {
	background: var(--bg-orange);
}

.toggle-switcher:checked+.toggle-switch .toggle-label-background:before {
	right: 40px;
	content: 'Bright';
}

.toggle-switcher:checked+.toggle-switch:before {
	background: var(--bg-white) url(/static/images/sun-icon.png) no-repeat 2px 3px;
	animation-name: switch;
	animation-duration: 350ms;
	animation-fill-mode: forwards;
}

.toggle-switch {
	width: 90px;
	height: 40px;
	background: var(--bg-grey);
	border-radius: 100px;
	display: flex;
	position: relative;
	transition: all 350ms ease-in;
}

.toggle-label-background:before {
	content: 'Dark';
	position: absolute;
	top: 12px;
	font-weight: var(--font-weight-700);
	border-radius: 5px;
	right: 15px;
	transition: all 150ms ease-in;
	color: var(--font-white);
}

.toggle-switch:before {
	animation-name: reverse;
	animation-duration: 200ms;
	animation-fill-mode: forwards;
	transition: all 200ms ease-in;
	content: '';
	width: 30px;
	height: 30px;
	top: 5px;
	left: 4px;
	z-index: 10;
	position: absolute;
	border-radius: 82px;
	background: var(--bg-white) url(/static/images/moon-icon.png) no-repeat 6px 6px;
}

@keyframes switch {
	0% {
		left: 4px;
	}

	60% {
		left: 4px;
		width: 30px;
	}

	100% {
		left: 55px;
		width: 30px;
	}
}

@keyframes reverse {
	0% {
		left: 50px;
		width: 40px;
	}

	60% {
		left: 45px;
		width: 40px;
	}

	100% {
		left: 4px;
	}
}

.user-panel {
	align-items: center;
	background: var(--bg-orange);
	border-radius: 100%;
	display: flex;
	height: 65px;
	justify-content: center;
	min-width: 65px;
	padding: 0;
}

.user-panel-name {
	color: var(--font-white);
	font-size: var(--font-size-xxl);
	font-style: normal;
	font-weight: var(--font-weight-900);
}
/* .user-panel:before {
  position: absolute;
  content: '';
  background-image: url('account_480.png');
  width: 65px;
  height: 65px;
  pointer-events: none;
} */

.mobile-menu-overlay:before {
	background: #0000006b;
	position: fixed;
	width: 100%;
	display: none;
	height: 100%;
	left: 0;
	content: '';
	top: 93px;
}

.mobile-view-profile .mobile-sign-out {
	display: none;
	color: var(--font-white);
	text-align: right;
}

.mobile-navbar {
	display: none;
}

.mobile-menu-search input {
	display: none;
}

.top-navbar .mobile-menu-text {
	display: none;
}

.message-white-icon {
	height: 35px;
	left: 0;
	position: absolute;
	top: 5px;
	width: 36px;
}

.top-navbar ul .show-in-mobile {
	display: none;
}

.mobile-theme {
	display: none;
}

.mobile-menu-close {
	display: none;
}

.mobile-menu-overlay .mobile-menu-close {
	display: block;
}

.user-navigation-menu {
	display: none;
}

.mobile-view-profile.user-navigation {
	display: inline;
	position: relative;
}

.mobile-view-profile.user-navigation.connect {
	display: flex;
}

.user-navigation .user-navigation-menu {
	background: var(--bg-white);
	box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
	z-index: 11;
	border: var(--border-grey-1);
	padding: 10px;
	display: block;
	position: absolute;
	width: 150px;
	left: -105px;
	top: 76px;
	border-radius: 7px;

}

.user-navigation .user-navigation-menu ul {
	display: block;
}

.user-navigation .user-navigation-menu ul li {
	margin: 0 10px;
}

.user-navigation .user-navigation-menu ul li a {
	font-size: var(--font-size-xl);
	padding: 10px 0px;
	color: var(--font-black);
	text-decoration: none !important;

}

.user-navigation .user-navigation-menu:after,
.user-navigation .user-navigation-menu:before {
	bottom: 100%;
	right: 20px;
	border: solid transparent;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}


.user-navigation .user-navigation-menu:before {
	border-color: #c2e1f500;
	border-bottom-color: #ccc;
	border-width: 10px;

}

.top-navbar ul li.mobile-view-profile.connect a {
	display: flex;
	align-items: center;
}

@media screen and (min-width: 1019px) and (max-width: 1220px) {
	.top-navbar ul {
		justify-content: space-between;
	}

	.top-navbar ul li {
		margin: 0;
	}
}

@media screen and (min-width: 100px) and (max-width: 1020px) {
	.mobile-menu-overlay:before {
		display: block;
	}

	.mobile-navbar {
		display: block;
	}

	.top-navbar {
		position: fixed;
		background: var(--bg-orange);
		top: -100vh;
		width: 100%;
		z-index: 1;
		transition-duration: 500ms;
		transform: translateY(0px);
		height: 100vh;
		padding-left: 0;
		left: 0;
	}

	.top-navbar ul li {
		margin: 0 30px;
		border-top: 1px solid #ffffff7a;
	}

	.top-navbar ul li a {
		width: 100%;
		color: var(--font-white);
		padding: 18px 0px;
		font-size: var(--font-size-m);
	}

	.top-navbar ul li a:hover {
		color: var(--font-white);
	}
	.top-navbar ul {
		display: block;
	}

	.top-header {
		justify-content: space-between;
	}

	.mobile-view-menu {
		top: 0px;
		padding-top: 200px;
	}

	.user-panel {
		background: var(--bg-white);
		width: 65px;
	}

	.user-panel-name {
		color: var(--font-orange);
	}

	.mobile-view-profile .mobile-sign-out {
		display: inline-block;
	}

	.top-navbar ul .mobile-view-profile {
		position: absolute;
		top: 20px;
		width: calc(100% - 60px);
		border-top: 0px;
	}

	.top-navbar ul li.mobile-view-profile a {
		border-bottom: 0px;
		display: inline-block;
		width: 49%;
		text-decoration: underline;
	}

	li.mobile-view-profile.connect {
		position: relative;
	}

	.top-navbar .mobile-menu-search {
		position: absolute;
		top: 130px;
		width: calc(100% - 60px);
		border-top: 0px;
	}

	.mobile-menu-search input {
		display: block;
		width: 100%;
		background: var(--bg-white);
		height: 45px;
		border-radius: 10px;
		padding: 0px 15px;
		color: var(--font-black);
		outline: none;
		border: 0px;
		font-size: var(--font-size-xl);
	}

	.menu-search_icon {
		position: absolute;
		right: 20px;
		top: 10px;
	}

	.top-navbar .mobile-menu-text {
		display: block;
		flex: 1;
		color: var(--font-white);
		font-size: var(--font-size-m);
		font-weight: var(--font-weight-400);
		padding: 20px 0;
	}

	.message-icon {
		display: none;
	}

	.message-white-icon {
		display: block;
	}

	.message-icon-text {
		background: var(--bg-white);
		color: var(--bg-orange);
		bottom: 3px;
		top: inherit;
		width: 14px;
		min-width: auto;
		left: 20px;
		height: 14px;
		line-height: 15px;
		font-size: var(--font-size-10);
	}

	.menu-search_icon {
		width: 20px;
		height: 20px;
	}

	.top-navbar ul .show-in-mobile {
		display: block;
	}

	.mobile-menu-overlay .mobile-navbar {
		position: relative;
		z-index: 11;
	}

	.mobile-menu-overlay .mobile-navbar {
		position: absolute;
		right: 10px;
		top: 10px;
		width: 35px;
		height: 35px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}

	.mobile-menu-overlay .mobile-menu-show {
		display: none;
	}

	.user-navigation .user-navigation-menu {
		left: 0px;
	}

	.user-navigation .user-navigation-menu {
		left: 0;
		top: 95px;
	}

	.user-navigation .user-navigation-menu ul li a {
		font-size: var(--font-size-m);
		width: 100%;
	}

	.user-navigation .user-navigation-menu:after,
	.user-navigation .user-navigation-menu:before {
		right: 130px;
	}
}

@media screen and (min-width: 100px) and (max-width: 676px) {
	.top-navbar ul li a.link-active {
		color: var(--font-white);
	}
	.company-logo a span {
		font-size: var(--font-size-m);
		font-weight: var(--font-weight-800);
	}

	.top-header:after {
		margin-top: 60px;
	}

	main .wrapper-container {
		margin-top: 80px;
	}

	.top-header {
		padding: 5px 10px;
		width: calc(100% - 20px);
		height: 50px;
	}

	.company-logo img {
		height: 30px;
		width: 30px;
	}

	.mobile-navbar .mobile-menu-show {
		width: 30px;
	}
}


.link-active {
	color: var(--font-orange) !important;
}
.mobile-link-active {
    color: var(--font-black) !important;
}

/*-------- End of Top NavBar css--------*/
