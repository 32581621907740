.algo-editor-box {
	display: flex;
	padding: 30px 0px 0px 0px;
	align-items: center;
}

.algo-editor-box-item {
	flex: 1;
	padding: 10px;
	position: relative;
}

.algo-editor-box-item label {
	font-size: var(--font-size-m);
	color: var(--font-black);
	white-space: nowrap;
	display: block;
	margin-bottom: 10px;
}

.card-title.edit-input-box input {
	border: var(--border-grey-1);
	height: 32px;
	margin-left: 10px;
	border-radius: 5px;
	outline: none;

}

.algo-editor-box-item input[type="text"],
.algo-editor-box-item input[type="number"] {
	width: calc(100% - 80px);
	border: var(--border-grey-1);
	height: 43px;
	font-size: var(--font-size-l);
	border-radius: 10px;
	margin-top: 5px;
	padding: 5px 70px 5px 10px;
	outline: none;
}

.algo-editor-box-item input.error {
	border: var(--border-red-1);
}

input[type=number]::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

.algo-editor-box-item span {
	position: absolute;
	bottom: -5px;
	left: 20px;
	color: var(--font-red);
	font-size: var(--font-size-xs);
}

.algo-editor-box-item a {
	position: absolute;
	top: 51px;
	right: 30px;
	font-weight: bold;
	color: var(--font-grey2);
}

.algo-editor-box-item a {
	text-decoration: underline;
}

.algo-editor-box-item select {
	width: calc(100% - 0px);
	border: var(--border-grey-1);
	background: var(--bg-white);
	color: var(--font-black);
	height: 54px;
	font-size: var(--font-size-l);
	border-radius: 10px;
	margin-top: 5px;
	padding: 5px 10px;
	outline: none;
	font-weight: var(--font-weight-600);
}



.trade-action .search-box-item label {
	font-size: var(--font-size-s);
	color: var(--font-grey2);
	white-space: nowrap;
}

.algo-editor-action .search-box-item input[type=text] {
	width: calc(100% - 63px);
	border: var(--border-black-2);
	height: 43px;
	border-radius: 10px;
	padding: 5px 50px 5px 10px;
	margin-top: 5px;
	font-size: var(--font-size-l);
}

.algo-edit-graph {
	padding: 30px 0px;
}

.algo-edit-graph img {
	width: 100%;
}

.algo-edit-info {
	font-weight: var(--font-weight-600);
	font-size: var(--font-size-m);
	padding: 15px 0px;
	text-align: center;
}

.algo-edit-btn {
	display: flex;
	padding: 0px 20px 30px 20px;
	justify-content: space-between;
}

@media screen and (min-width:100px) and (max-width:676px) {
	.algo-editor-box {
		display: block;
	}

	.algo-edit-btn {
		padding: 0px 0px 30px 0px;
	}
}

.slider-info {
	display: flex;
	justify-content: space-between;
	padding: 10px 0px;
	width: 280px;

}

.slider-info span {
	color: var(--font-black);
	position: initial;
}

.tooltip .pophover {

	background: #2e2e2e;
	border: 2px solid #2e2e2e;
	position: absolute;
	min-width: 20px;
	padding: 5px;
	color: var(--font-white);
	top: -30px;
	text-align: center;
	font-size: var(--font-size-11);
}

.tooltip .pophover:after,
.pophover:before {
	top: 100%;
	left: 50%;
	border: solid transparent;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}


.tooltip .pophover:before {
	border-width: 8px;
	border-color: #2e2e2e #c2e1f500 #c2e1f500;
	margin-left: -8px;
	left: 50%;
}

.slider {
	-webkit-appearance: none;
	width: 280px;
	height: 8px;
	background: #f37921;
	outline: none;
	opacity: 0.7;
	-webkit-transition: .2s;
	border-radius: 5px;
	transition: opacity .2s;
}

.slider:hover {
	opacity: 1;
}

.slider::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 18px;
	height: 18px;
	background: var(--bg-orange);
	border-radius: 100%;
	cursor: pointer;
}

.slider::-moz-range-thumb {
	width: 25px;
	height: 25px;
	background: #04AA6D;
	cursor: pointer;
}