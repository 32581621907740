@import url("https://fonts.googleapis.com/css?family=Inter:400,600italic,700,900,300,500,600,800|Piazzolla:700|KoHo:700|Montserrat:400,500,600|Mulish:700,800|Roboto:400,700,600|Rubik:400,500");

.loader {
    margin: 200px auto;
    width: 350px;
}
.loading-graphic {
	position: absolute;
}
.binary-wrap {
	width: 235px;
	height:168px;
	margin-left: 30px;
	color: #f3cedf;
	font-size: 20px;
	overflow: hidden;
  }
  
  .binary-animation {
	  font-family: 'Courier New', Courier, monospace;
	  font-size: 30px;
	  font-weight: 700;
	  width: 380px;
	  min-width: 200px;
	  overflow: hidden;
	  animation: slide-right 10s linear infinite;
  }
  @keyframes slide-right {
	  from {
		  transform: translateX(-100px);
		}
		50% {
		  transform: translateX(0px);
		}
		to {
		  transform: translateX(-100px);
		}
  }