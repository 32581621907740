
  :root {
    --font-size-l: 18px;
    --font-size-m: 16px;
    --font-size-s: 14px;
    --font-size-13: 13px;
    --font-size-15: 15px;
    --font-size-xl: 20px;
    --font-size-10: 10px;
    --font-size-xs: 12px;
    --font-size-xxl: 24px;
    --font-size-xxxl: 30px;    
    --font-size-36: 36px;
    --font-size-28: 28px;
    --font-size-26: 26px;
    --font-size-40: 40px;
    --font-size-50: 50px;
    --font-size-9: 9px;
    --font-size-7: 7px;
    --font-size-8: 8px;
    --font-size-11: 11px;

    --font-weight-300: 300;
    --font-weight-400: 400;
    --font-weight-500: 500;
    --font-weight-600: 600;
    --font-weight-700: 700;
    --font-weight-800: 800;
    --font-weight-900: 900;

    --font-family-rubik: "Rubik", Helvetica;
    --font-family-inter: "Inter", Helvetica;
    --font-family-roboto-regular: 'robotoregular';
    --font-family-roboto: 'Roboto';
	--font-family-montserrat: "Montserrat", Helvetica;
  }