.card-main-container {
	display: flex;
	min-height: 350px;
	padding: 15px 0px;
	margin-bottom: 15px;
	font-size: var(--font-size-m);
	justify-content: space-between;
}

.card {
	padding: 15px;
}


.card-header {
	display: flex;
	justify-content: space-between;
	border-bottom: var(--border-black-1);
	padding-bottom: 5px;
	align-items: center;
}

.card-title {
	margin: 0px;
	padding: 0;
	font-size: var(--font-size-xxl);
	color: var(--font-black);
	font-family: var(--font-family-roboto);
}

.card-title img {
	width: 27px;
	position: relative;
	top: 4px;
	height: 27px;
}

.card-title svg {
	width: 31px;
	height: 31px;
	position: relative;
	top: 4px;
}

.card-tools {
	display: flex;
}

.card-tools-text {
	color: var(--font-green);
	font-weight: var(--font-weight-600);
	font-size: var(--font-size-m);
	margin: 5px 0px 0px 5px;
}

.card-tools-icon {
	width: 28px;
}

.card-tools-icon img {
	width: 100%;
}


.card-tools-text {
	color: var(--font-green);
	font-weight: var(--font-weight-600);
	font-size: var(--font-size-m);
	margin: 5px 0px 0px 5px;
}

.card-tools-icon {
	width: 28px;
}

.card-tools-icon img {
	width: 100%;
}
.card-header-v2 {

	padding: 10px 15px;
	background: var(--bg-light-grey-2);
	border-bottom: 0px;
}

.card-header-v2 .card-title{
	font-family: var(--font-family-inter);
	font-weight: var(--font-weight-300);
	

}
@media screen and (min-width:100px) and (max-width:1020px) {
	.card-main-container {
		display: block;
	}
}

@media screen and (min-width:100px) and (max-width:676px) {
	.card-main-container .card {
		padding: 15 10px;
	}
    .card-main-container .padding-top-0{
        padding-top: 0px;
    }
.card-title{
	font-size: var(--font-size-s);
}
.card-title img{
	width: 16px;
}
.card-tools-text{
	font-size: var(--font-size-9);
	margin-top: 3px;
}
.card-tools-icon{
	width: 17px;
}
}
