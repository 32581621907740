.noalpacadialog-model::before {
	background: url(../../../../static/images/question-bg.png);
	background-size: 100% 100%;
	content: '';
	width: 300px;
	height: 300px;
	position: absolute;
	right: 0;
	z-index: -1;
	top: 70px;
	opacity: 0.8;
}

.noalpacadialog-info {
	color: var(--font-black);
	max-width: 65%;
}

.noalpacadialog-info div {
	line-height: 25px;
}

.noalpacadialog-footer {
	padding-top: 30px;
	max-width: 65%;
	text-align: left;
	display: flex;
	justify-content: space-between;
}

.noalpacadialog-footer a {
	margin-left: 40px;
}

@media screen and (min-width:100px) and (max-width:676px) {

	.noalpacadialog-model::before {
		opacity: 0.3;
	}
	
	.noalpacadialog-footer {
		max-width: 100%;
	}

	.noalpacadialog-info {
		max-width: 100%;
	}
}