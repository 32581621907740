.signin-main {
	background-color: var(--font-white);
	display: flex;
	width: 100%;
	height: 99vh;
	margin: auto;
}

.signin-main .welcome-back {
	color: var(--bg-white);
	font-weight: 700;
	margin-bottom: 10px;

}

.signin-main .sign-text {
	color: var(--bg-white);
	font-weight: 500;
	line-height: 24px;
	font-size: var(--font-size-m);
}

.signup-link-txt {
	padding: 0 30px 0 30px;
	color: var(--font-blue);
	font-size: var(--font-size-l);
    font-weight: var(--font-weight-600);
	font-size: var(--font-size-m);
}

.signin-main .sign-right-side {
	align-items: center;
	display: flex;
	flex-direction: column;
	margin-top: 34px;
	flex: 1;
}
.signin-main .sign-right-side form {
	text-align: center;
}

.signin-main .form-header {
	color: var(--log-cabin);
	font-weight: 800;
	text-align: center;

}

.signin-main .form-main-container {
	display: flex;
	flex-direction: column;
	width: 300px;
	margin: auto;
	text-align: left;
}

.signin-main .button-devider {
	justify-content: center;
    margin-top: 25px;
    display: flex;
}


.signin-main .button-devider-info {
	height: 24px;
	letter-spacing: 0;
	line-height: 24px;
	margin-left: 17px;
	opacity: 0.6;
	text-align: center;
	white-space: nowrap;
	width: 22px;
}

.signin-main .button-devider-bg {
	height: 1px;
	margin-left: 17px;
	margin-top: 11.5px;
	width: 136px;
	background: var(--bg-grey3);

}

.signin-main .button-container {
	border-radius: 46px;
	height: 56px;
	margin-right: 30.0px;
	margin-top: 16px;
	position: relative;
	width: 327px;
}

.signin-main .button-div {
	margin-top: 15px;
}

.signin-main .button-icons {
	position: relative;
	width: 24px;
	left: -10px;
}


.signin-main .button-blue {
	background: var(--bg-marino);
	align-items: center;
	border-radius: 55px;
	display: flex;
	height: 56px;
	font-family: var(--Roboto);
	width: 300px;
	border: var(--border-grey-1);
	cursor: pointer;
	justify-content: center;
	font-size: var(--font-size-m);
	font-weight: var(--font-weight-600);
	color: var(--font-white);
}


.signin-main .valign-text-middle {
	display: flex;
	flex-direction: column;
	justify-content: center;
}


.signin-main .button-orange {
	align-items: center;
	background-color: var(--mt-orange);
	border-radius: 50px;
	height: 52px;
	width: 300px;
	font-family: var(--Roboto);
	font-weight: var(--font-weight-600);
	color: var(--font-white);
	font-size: var(--font-size-m);
	border: var(--border-grey-1);
	cursor: pointer;

}

.signin-main .button-white {
	background-color: var(--bg-white);
	align-items: center;
	border-radius: 55px;
	display: flex;
	height: 56px;
	width: 300px;
	border: var(--border-grey-1);
	cursor: pointer;
	justify-content: center;
	font-size: var(--font-size-m);
	font-weight: var(--font-weight-600);
	color: var(--font-black);
}


.signin-main .button-icons {
	position: relative;
	width: 24px;
	left: -10px;
}


.signin-main .button-blck {
	background: var(--bg-black);
	align-items: center;
	border-radius: 55px;
	display: flex;
	height: 56px;
	width: 300px;
	border: var(--border-grey-1);
	cursor: pointer;
	justify-content: center;
	font-size: var(--font-size-m);
	font-weight: var(--font-weight-600);
	color: var(--bg-white);
}


.signin-main .header-large {
	font-family: var(--font-family-inter);
	font-size: var(--font-size-40);
	font-style: normal;
	font-weight: 800;
	color: var(--font-white);
	letter-spacing: 0;
}


.signin-main .form-control-text-box {
	background: #f8f8f8;
	padding: 5px;
	border: 1px #edeaea solid;
	border-radius: 5px;
	height: 35px;
	margin-top: 7px;
	outline: none;
}

.signin-main.w elcome-text {
	margin-top: 53px;
	margin-bottom: 15px;

}

.signin-main .form-group-container {
	display: flex;
	flex-direction: column;
	padding-bottom: 15px;
}

.signin-main .form-header {
	font-family: var(--font-family-inter);
	font-size: var(--font-size-36);
	font-style: normal;
	font-weight: 800;
	letter-spacing: 0;
	margin-bottom: 20px;
}

.signin-main .button-div-error {
    padding: 5px 10px;
}

span.error {
	color: var(--font-red);
}
@media screen and (min-width:10px) and (max-width:730px) {
	.signin-main .form-header {
		padding-bottom: 30px;
	}

	 .signin-main {
		display: block;
	}
}