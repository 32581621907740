.list-wrapper {
	display: block;
	max-width: 100%;
	margin: 30px 0px;
	font-family: var(--font-family-roboto-regular);
	font-weight: var(--font-weight-400);
}

.list tr:not(.separator):nth-child(even) {
	background: rgba(196, 196, 196, 0.2);
}

.list tr:not(.separator):nth-child(odd) {
	background: #FFF
}

.list-wrapper .list .separator td {
	border-top: 1px #ccc solid;
	padding: 0px;
}

.list {
	width: 100%;
	border-spacing: 0;
}

.list tr th {
	text-align: left;
	vertical-align: top;
	font-weight: var(--font-weight-400);
	color: var(--font-grey);
	font-size: var(--font-size-s);
	padding: 5px 5px;
}

.list tr th img {
	width: 7px;
}

.list td {
	padding: 18px 5px;
	color: var(--font-black);
	font-size: var(--font-size-m);
	font-family: var(--font-family-roboto);
}

.list td .exapnder img {
	width: 25px;
	position: relative;
	top: 4px;
}

.list .multi-line-cell span:last-child {
	display: block;
	font-size: var(--font-size-13);
	padding-top: 5px;
}

.list .green-value {
	color: var(--font-green);
}

.list .red-value {
	color: var(--font-red);
}

.list .header-notation span:last-child {
	display: block;
}

.list .symbol-n-delta.red-value {
	background: rgba(224, 83, 83, 0.25);
	border-radius: 12px;
	padding: 10px;
	width: 100px;
	display: flex;
	justify-content: space-between;
}

.list .symbol-n-delta.green-value {
	background: rgba(99, 220, 133, 0.25);
	border-radius: 12px;
	padding: 10px;
	width: 100px;
	display: flex;
	justify-content: space-between;
}

.list .symbol-n-delta span:first-child {
	font-weight: var(--font-weight-700);
}

.list .symbol-n-delta span:last-child {
	font-weight: var(--font-weight-400);
	font-size: var(--font-size-s);
	font-family: var(--font-family-roboto);
	opacity: 0.75;
}

.list .account-status span {
	margin-right: 5px;
	position: relative;
	top: 2px;
}

.list .name-n-timestamp span:last-child {
	opacity: 0.6;
}

.list-wrapper.sidebar-widget table.list tr {
	background: none;
}

.sidebar-widget .list .stock-info span label:last-child, .list .stock-info span label:last-child{
	opacity: .6;
	font-size: var(--font-size-13);
	padding-top: 5px;
	display: block;
	width: 120px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.sidebar-widget .list .stock-icon {
	margin-right: 5px;
}

.sidebar-widget .list .stock-info {
	display: flex;
}
.list-wrapper .list .order-datetime {
	font-weight: var(--font-weight-400);
    color: var(--font-grey);
    font-size: var(--font-size-s);
}

.processBar {
	position: relative;
	left: 50%;
	top: 50%;
	z-index: 1;
	width: 100px;
	height: 100px;
	margin: -76px 0 0 -76px;
	border: 16px solid #f3f3f3;
	border-radius: 50%;
	border-top: 16px solid #3498db;
	-webkit-animation: spin 2s linear infinite;
	animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}


/* Add animation to "page content" */

.animate-bottom {
	position: relative;
	-webkit-animation-name: animatebottom;
	-webkit-animation-duration: 1s;
	animation-name: animatebottom;
	animation-duration: 1s
}

@-webkit-keyframes animatebottom {
	from {
		bottom: -100px;
		opacity: 0
	}
	to {
		bottom: 0px;
		opacity: 1
	}
}

@keyframes animatebottom {
	from {
		bottom: -100px;
		opacity: 0
	}
	to {
		bottom: 0;
		opacity: 1
	}
}

.list>tbody {
	/* Make sure the inner table is always as wide as needed */
	height: 100px;
}

.list.loading tbody {
	position: relative;
}

.list .dropdown-menu {
	float: left;
	position: relative;
}

.list .dropdown-menu .dropdown-dots-icon {
	cursor: pointer;
	height: 20px;
}

.list .dropdown-menu-item {
	display: none;
	position: absolute;
	background: var(--bg-white);
	min-width: 100px;
	overflow: auto;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	right: 0;
	bottom: 0;
	z-index: 1;
	width: 160px;
	padding: 0px 10px;
	border: var(--border-grey-1);
}

.list .dropdown-menu-item a {
	color: var(--font-black);
	padding: 6px 5px 11px 5px;
	text-decoration: none;
	display: block;
	font-size: var(--font-size-s);
	border-bottom: var(--border-black-1);
	cursor: pointer;
}

.list .dropdown-menu-item a span {
	opacity: 0.6;
}

.list .dropdown-menu-item a:hover span {
	opacity: 1;
}

.list .dropdown-menu-item a:last-child {
	border-bottom: 0px;
}

.list .show {
	display: block;
}

.list .dropdown-menu-icon {
	position: relative;
	top: 6px;
	left: -5px;
	width: 22px;
}


@media screen and (min-width:100px) and (max-width:676px) {
	.sidebar-widget .list .stock-icon img {
		width: 22px;
	}

	.list tr th {
		font-size: var(--font-size-8)
	}
	.list td {
		padding: 12px 5px;
		font-size: var(--font-size-9)
	}
	.list .multi-line-cell span:last-child {
		font-size: var(--font-size-8);
	}
	.list tr th,
	.list tr td {
		padding: 5px 2px;
	}
	.list .account-status {
		display: flex;
	}
	.list .account-status img {
		width: 7px;
	}
	.list tr th img {
		width: 5px;
	}
	.list td .exapnder img {
		width: 15px;
	}
	.list .symbol-n-delta span:last-child {
		font-size: var(--font-size-7);
	}
	.list .symbol-n-delta.red-value {
		width: 65px;
	}
	.list .symbol-n-delta.green-value {
		width: 65px;
	}
	.list .symbol-n-delta.green-value {
		padding: 5px;
	}
	.list-wrapper .list .dropdown-dots-icon {
		width: 3px;
		background-size: 100% 100%;
		height: initial;
	}
	.list .symbol-n-delta.red-value {
		padding: 5px;
	}

	.list-wrapper {
		margin: 15px 0px;
	}
	.list .dropdown-menu-icon{
		width: 18px;
	}
	.list .dropdown-menu-item a{
		font-size: var(--font-size-xs);
		padding: 3px 4px 9px;
	}
	
}
