.account-details {
	display: flex;	
	padding: 10px 15px 10px 15px;
	flex-wrap: wrap;
}

.account-details .detail-item {
	width: calc(33% - 10px);
	padding: 15px 5px;
	text-align: center;
}

.account-details .detail-item label {
	font-size: var(--font-size-s);
	color: var(--font-grey);
	display: block;
	position: relative;
}

.account-details .detail-item div {
	font-size: var(--font-size-xl);
	padding-top: 5px;
	display: block;
	color: var(--font-Darkgrey);
	font-weight: var(--font-weight-600);
}

.account-details .detail-item span {
	position: absolute;
	width: 13px;
	height: 13px;
	border-radius: 100%;
	background: var(--bg-light-grey2);
	color: var(--font-white);
	font-size: var(--font-size-xs);
	text-align: center;
	margin-left: 5px;
}
@media screen and (min-width:100px) and (max-width:676px) {
	
	.account-details .detail-item div {
		font-size: var(--font-size-xs);
	}

	.account-details .detail-item label {
		font-size: var(--font-size-xs);
	}
	.trade-info .stock-desc span{
		font-size: var(--font-size-xs);
	}
	.trade-info .stock-detail div span{
		font-size: var(--font-size-xs);

	}
}