
  :root {

    --font-orange: #FF6B01;
    --font-black: #000;
    --font-white: #fff;
    --font-yellow: #ffc628;
    --font-grey: #8E8E8E;
    --font-grey2: #9B9B9B;
    --font-grey3:#A9A9A9;
    --font-Darkgrey: #4A4848;
    --font-green:#56C876;
    --font-blue:#487FD9;
    --font-red:#E05353;
    --font-black2:#252B42;
    
    --ebony-clay: #252b42;

    --bg-orange: #ff6b00;
    --bg-yellow: #ffc628;    
    --bg-white: #fff;    
    --bg-black: #000;    
    --bg-grey: #626262;
    --bg-grey3: #ccc;
    --bg-light-grey:#f8f8f8; 
    --bg-green:#56C876;
    --bg-red:#E05353;
    --bg-grey-2:#F2F2F2;
    --bg-light-grey-2:#EEEEEE;
    --bg-dark-grey:#BDBABA;
    --bg-marino:#4772A0;
    --bg-light-grey3:#DADADA;
    --bg-white3:#e5e5e512;
    

	  --mt-orange: #ff6b01;
	
    --border-orange-1: 1px #ea9e68 solid;
    --border-grey-1: 1px #ccc solid;  
    --border-black-1: 1px #000 solid;   
    --border-black-2: 2px rgba(0, 0, 0, 0.7) solid;   
    --border-grey-1: 1px #ccc solid; 
    --border-red-1: 1px #E05353 solid;
        
    
  }