.headline-item {
	margin: 25px 0px 32px 0px;
	text-align: left;
	font-family: var(--font-family-roboto);
}

.headline-item label {
	font-size: var(--font-size-s);
	color: var(--font-grey);
}

.headline-item div, .headline-item div a {
	font-size: var(--font-size-l);
	padding-top: 5px;
	line-height: 24px;
	color: var(--font-black);
	font-weight: var(--font-weight-400);
}

.headline-item span {
	width: 18.61px;
	height: 18.61px;
	border-radius: 100%;
	display: inline-block;
	margin-right: 5px;
}

.headline-item span img {
	width: 100%;
}

@media screen and (min-width:100px) and (max-width:676px) {
	.headline-item div {
		font-size: var(--font-size-11);
	}
	.headline-item label {
		font-size: var(--font-size-9);
	}
	.headline-item span {
		width: 11px;
		height: 11px;
	}
	.headline-item {
		margin: 5px 0 12px;
	}
	a.link-text {
		font-size: var(--font-size-11);
	}
}

.card-tools-text .green-value {
	color: var(--font-green);
}

.card-tools-text .red-value {
	color: var(--font-red);
}