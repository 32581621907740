.trade-action {
	display: flex;
	justify-content: space-between;
	padding: 20px;
}
.trade-box-item {
	flex: 1;
	padding: 10px;
	position: relative;
}
.trade-icon img {
	top: 9px;
	left: 6px;
}

.symbol-type-selector {
	padding: 0 25px;
	display: inline-block;
	margin-top: 20px;
}

.symbol-type-selector select {
	height: 30px;
    width: 100px;
    border: var(--border-black-2);
    color: var(--font-black);
    border-radius: 10px;
	background: var(--bg-white);
}
 

.switcher-container {
	width: 200px;
}
.trade-box {
	display: flex;
	padding: 10px 0px 0px 0px;
	align-items: center;
}

.trade-box-item label {
	font-size: var(--font-size-s);
	color: var(--font-grey2);
	white-space: nowrap;
	display: block;
}

.trade-box-item input[type="text"],
.trade-box-item input[type="number"] {
	width: calc(100% - 80px);
	border: var(--border-grey-1);
	color: var(--font-black);
	height: 43px;
	font-size: var( --font-size-l);
	border-radius: 10px;
	margin-top: 5px;
	padding: 5px 70px 5px 10px;
	outline: none;
	background: var(--bg-white);
}
.trade-box-item input.error{
	border: var(--border-red-1);
}

input[type=number]::-webkit-inner-spin-button {
	-webkit-appearance: none;
  }

.trade-box-item span{
	position: absolute;
	bottom: -5px;
	left: 20px;	
	color: var(--font-red);
	font-size: var(--font-size-xs);
}
.trade-box-item a {
	position: absolute;
	top: 51px;
	right: 30px;
	font-weight: bold;
	color: var(--font-grey2);
}
  
.trade-box-item a {
	text-decoration: underline;
}

.trade-box-item select {
	width: calc(100% - 0px);
	border: var(--border-grey-1);
	height: 54px;
	font-size: var( --font-size-l);
	color: var(--font-black);
	background: var(--bg-white);
	border-radius: 10px;
	margin-top: 5px;
	padding: 5px 10px;
	outline: none;
	font-weight: var(--font-weight-600);
}



.trade-action .mobile-menu-text {
	display: none;
}

.trade-action .toggle-switcher {
	display: none;
}

.trade-action .toggle-switcher:checked+.toggle-switch {
	background: var(--bg-grey);
}

.trade-action .toggle-switcher:checked+.toggle-switch:before {
	background: var(--bg-white);
	animation-name: tradeswitch;
	animation-duration: 350ms;
	animation-fill-mode: forwards;
}

.trade-action .toggle-switch {
	width: 65px;
	height: 30px;
	background: var(--bg-orange);
	;
	border-radius: 100px;
	display: flex;
	position: relative;
	transition: all 350ms ease-in;
}

.trade-action .toggle-switch:before {
	animation-name: reverse;
	animation-duration: 200ms;
	animation-fill-mode: forwards;
	transition: all 200ms ease-in;
	content: '';
	width: 20px;
	height: 20px;
	top: 5px;
	left: 4px;
	z-index: 10;
	position: absolute;
	border-radius: 82px;
	background: var(--bg-white);
	z-index: 1;
}

.trade-action .toggler {
	display: flex;
	flex-direction: row-reverse;
	padding: 10px;
	align-items: center;
	margin-top: 20px;
}

.trade-action .toggler .label {
	padding: 8px;
	font-size: var(--font-size-xl);
}

@keyframes tradeswitch {
	0% {
		left: 4px;
	}
	60% {
		left: 4px;
		width: 20px;
	}
	100% {
		left: 42px;
		width: 20px;
	}
}

@keyframes reverse {
	0% {
		left: 35px;
		width: 30px;
	}
	60% {
		left: 30px;
		width: 30px;
	}
	100% {
		left: 4px;
	}
}

.trade.tab{
	padding: 20px;
	
}

.trade.tab .nav{
	border-bottom: var(--border-grey-1);
	justify-content: flex-start;
	margin: 0px 0px 10px 0px;
}
.trade.tab .outlet{
	border: 0;
}
.trade.tab ul.nav li{
	border: 0;
	font-size: var(--font-size-m);
	text-transform: capitalize;
	color: var(--font-grey2);
	font-family: var(--font-family-inter);
	font-weight: normal;
	background: none;
	flex: none;
	width: 250px;
	padding: 12px 0px;
}
.trade.tab ul.nav li::before{
	background: url(../../../static/images/quick-tab-icon.png);
	content: '';
	width: 6px;
	height: 12px;
	opacity: 0.4;
	margin: 0px 5px;


}
.trade.tab ul.nav li.active::before{
	opacity: 1;
}
.trade.tab ul.nav li.active::after{
	height: 2px;
	background: var(--bg-orange);
	content: '';
	position: absolute;
	bottom: 0;
	width: 95%;
}
.trade.tab ul.nav li.active{
	color: var(--font-black);
	top: 0;
}
@media screen and (min-width:100px) and (max-width:676px) {

	.trade-box-item select {
		font-size: var( --font-size-s);		
	}
	.trade-box-item input[type="text"], .trade-box-item input[type="number"]{
		font-size: var( --font-size-s);	
	}
	.trade.tab ul.nav li{
		width: 45%;
	}

	.trade-icon img {
		top: 4px;		
	}
	.list-wrapper .list .order-datetime {
		font-size: var(--font-size-8);
	}
	#trade-chart{
		padding:10px 5px;
	}
		

	.trade-action{
		padding: 10px 5px;
	}
	.trade.tab {
		padding:10px 5px;
	}
	.trade-action{ display:block;}
	.trade-action .toggler{
		margin-top: 0;
	}
	.switcher-container {
		width: 200px;
		margin: auto;
	}
	.trade .trade-box {
		display: block;
	}
	.trade .quick-trade-single-col{
		width: auto;
	}
}