
.main-container {
	flex: 1;
	margin-right: 15px;
}

.right-side-bar {
	background: var(--bg-light-grey);
	width: 30%;
	min-width: 400px;
}

@media screen and (min-width:100px) and (max-width:1020px) {

	.main-container {
		margin-right: 0;
		height: 300px;
	}
	.right-side-bar {
		width: 100%;
		min-width: auto;
		margin-top: 20px;
		background: none;
	}
}

.view-container {
	flex: 1;
}

.border-grey-1 {
	border: var(--border-grey-1);
}

.margin-right-20 { 
    margin-right: 20px;
}

.text-right { 
    text-align: right;
}

.fullscreen-icon{
	margin-top: 20px;
}
.fullscreen-icon img{
	width: 18px;
	height: 18px;
	cursor: pointer;
}

