.tab {
	height: auto;
	color: #FF6B01;
}

.tab ul.nav {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0px;
	margin: 0px;
	position: relative;
}

.sm-tab .tab ul.nav {
	justify-content: left;
}

.sm-tab .tab ul.nav li {
	flex: none;
}

.sm-tab .tab .outlet {
	border: 0;
	border-top: var(--border-black-1);
}

.tab ul.nav li {
	flex: 1;
	padding: 12px 15px;
	color: #8E8E8E;
	font-size: var(--font-size-s);
	text-transform: uppercase;
	list-style: none;
	font-weight: var(--font-weight-700);
	cursor: pointer;
	transition: all 0.7s;
	border: var(--border-black-1);
	border-bottom: 0px;
	background: var(--bg-grey-2);
	margin-right: 10px;
	align-items: center;
	display: flex;
}

.tab ul.nav li:last-child {
	margin-right: 0px;
}

.tab-title {
	flex: 1;
}

.tab .outlet {
	border: var(--border-black-1);
}

.tab ul.nav li.active {
	background: var(--bg-white);
	position: relative;
	color: var(--font-orange);
	top: 1px;
	border-radius: 12px 0px 0px 0px;
}

.active .right-info {
	color: var(--font-black);
}

.right-info {
	font-size: var(--font-size-m);
}

.tab-group-info {
	color: var(--font-Darkgrey);
	padding: 0px 40px;
	font-size: var(--font-size-s);
	font-family: var(--font-family-roboto);
}

.tab-icon {
	width: 45px;
	height: 27px;
	margin-right: 10px;
}

.tab-icon img {
	width: 27px;
}



@media screen and (min-width:100px) and (max-width:840px) {
	.tab-group-info {
		padding: 0px 10px;
	}
}

@media screen and (min-width:100px) and (max-width:676px) {
	.tab ul.nav li.active {
		border-radius: 10px 0px 0px 0px;
	}
	.tab-group-info {
		position: absolute;
		top: -24px;
	}
	.tab ul.nav li {
		padding: 7px 7px;
		font-size: var(--font-size-8);
	}
	
	.right-info {
		font-size: var(--font-size-8);
	}
	.tab-icon img {
		width: 16px;
	}
	.tab-icon {
		width: 16px;
		height: 16px;
		margin-right: 5px;
	}
}
.large-group-info {
	display: flex;
	font-size: var(--font-size-xxl);
	color: var(--font-black);
	font-family: var(--font-family-roboto);
	padding: 0px 15px 0px 0px;
	font-weight: var(--font-weight-700);
}

.large-group-info img {
	width: 27px;
	margin-right: 5px;
}

.large-group-info svg {
	width: 27px;
	height: 27px;
	margin-right: 5px;
}