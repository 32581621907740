:root {
	--tooltip-text-color: white;
	--tooltip-background-color: black;
	--tooltip-margin: 30px;
	--tooltip-arrow-size: 6px;
}

.pophover-wrapper {
	display: inline-block;
	position: relative;
}

.pophover {
	position: absolute;
	border-radius: 4px;
	left: 50%;
	padding: 15px;
	font-size: var(--font-size-s);
	line-height: 1;
	z-index: 1;
	white-space: nowrap;
}


.pophover.top {
	top: calc(var(--tooltip-margin) * -1);
}


.pophover.top::before {
	top: 100%;
	border-top-color: var(--tooltip-background-color);
}


.pophover.right {
	left: 100%;
	top: 50%;
	transform: translateX(0) translateY(-50%);
	box-shadow: 0 8px 16px #0003;
	background: var(--bg-white);
	border: var(--border-grey-1);
	width: 300px;
}

.pophover.bottom {
	bottom: calc(var(--tooltip-margin) * -1);
}


.pophover.left {
	left: auto;
	right: calc(100% + var(--tooltip-margin));
	top: 50%;
	transform: translateX(0) translateY(-50%);
}