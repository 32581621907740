
.quick-trage-info {
	position: absolute;
	top: 35px;
	right: -6px;
}

.quick-trade-single-col {
	width: 33.33%;
}

.trade-portfolio .list tr:not(.separator):nth-child(2n+1), 
.trade-portfolio .list tr:not(.separator):nth-child(2n) {
	background: transparent;
}
.trade-portfolio .list tr th img { 
	display: none;
}

.order-buttons {
	display: flex;
}

.order-buttons .margin-left-auto {
	margin-left: auto;
}

.order-buttons .close-button {
	margin-left: 10px;
}