@import "https://fonts.googleapis.com/css?family=Inter:400,600italic,700,900,300,500,600,800|Piazzolla:700|KoHo:700|Montserrat:400,500,600|Mulish:700,800|Roboto:400,700,600|Rubik:400,500";
.loader {
  width: 350px;
  margin: 200px auto;
}

.loading-graphic {
  position: absolute;
}

.binary-wrap {
  width: 235px;
  height: 168px;
  color: #f3cedf;
  margin-left: 30px;
  font-size: 20px;
  overflow: hidden;
}

.binary-animation {
  width: 380px;
  min-width: 200px;
  font-family: Courier New, Courier, monospace;
  font-size: 30px;
  font-weight: 700;
  animation: slide-right 10s linear infinite;
  overflow: hidden;
}

@keyframes slide-right {
  from {
    transform: translateX(-100px);
  }

  50% {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100px);
  }
}

.headline-item {
  text-align: left;
  font-family: var(--font-family-roboto);
  margin: 25px 0 32px;
}

.headline-item label {
  font-size: var(--font-size-s);
  color: var(--font-grey);
}

.headline-item div, .headline-item div a {
  font-size: var(--font-size-l);
  color: var(--font-black);
  line-height: 24px;
  font-weight: var(--font-weight-400);
  padding-top: 5px;
}

.headline-item span {
  width: 18.61px;
  height: 18.61px;
  border-radius: 100%;
  margin-right: 5px;
  display: inline-block;
}

.headline-item span img {
  width: 100%;
}

@media screen and (min-width: 100px) and (max-width: 676px) {
  .headline-item div {
    font-size: var(--font-size-11);
  }

  .headline-item label {
    font-size: var(--font-size-9);
  }

  .headline-item span {
    width: 11px;
    height: 11px;
  }

  .headline-item {
    margin: 5px 0 12px;
  }

  a.link-text {
    font-size: var(--font-size-11);
  }
}

.card-tools-text .green-value {
  color: var(--font-green);
}

.card-tools-text .red-value {
  color: var(--font-red);
}

.chart:fullscreen {
  width: 100%;
  height: 100%;
}

.chart {
  width: 100%;
  float: left;
  height: 400px;
  position: relative;
}

.highcharts-draw-mode {
  cursor: crosshair;
}

.highcharts-bindings-wrapper * {
  box-sizing: content-box;
}

.highcharts-bindings-wrapper {
  width: 40px;
  height: 100%;
  z-index: 10;
  display: block;
  position: absolute;
  top: 0;
}

.highcharts-stocktools-popup {
  width: 100%;
}

.highcharts-menu-wrapper {
  float: left;
  width: 40px;
  height: calc(100% - 50px);
  padding: 10px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.highcharts-bindings-wrapper .highcharts-submenu-wrapper {
  z-index: 10;
  width: 40px;
  background: #fff;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.highcharts-bindings-wrapper .highcharts-arrow-wrapper {
  text-align: center;
  width: 40px;
  font-size: 1.5em;
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.highcharts-bindings-wrapper .highcharts-arrow-wrapper > div {
  cursor: pointer;
}

.highcharts-bindings-wrapper .highcharts-arrow-down {
  filter: progid:DXImageTransform. Microsoft. BasicImage(rotation= 1);
  background-size: cover;
  transform: rotate(90deg);
}

.highcharts-bindings-wrapper .highcharts-arrow-up {
  width: 25px;
  cursor: pointer;
  user-select: none;
  -o-user-select: none;
  filter: progid:DXImageTransform. Microsoft. BasicImage(rotation= 3);
  background-size: cover;
  outline: none;
  display: inline-block;
  transform: rotate(-90deg);
}

.highcharts-bindings-wrapper .highcharts-arrow-right {
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: contain;
}

.highcharts-bindings-wrapper .highcharts-arrow-left.highcharts-arrow-right {
  filter: progid:DXImageTransform. Microsoft. BasicImage(rotation= 2);
  transform: rotate(0deg);
}

.highcharts-bindings-wrapper .highcharts-arrow-left {
  filter: progid:DXImageTransform. Microsoft. BasicImage(rotation= 2);
  transform: rotate(180deg);
}

.highcharts-bindings-wrapper ul {
  width: 40px;
  float: left;
  margin: 0;
  padding: 0;
  transition: margin .25s;
}

.highcharts-bindings-wrapper > ul {
  width: 40px;
  position: relative;
}

.highcharts-bindings-wrapper .highcharts-stocktools-toolbar li {
  clear: both;
  width: 100%;
  height: 40px;
  cursor: pointer;
  background-color: #f7f7f7;
  margin-bottom: 3px;
  padding: 0;
  list-style: none;
  position: relative;
}

.highcharts-bindings-wrapper .highcharts-stocktools-toolbar li.highcharts-disabled-btn {
  cursor: default;
}

.highcharts-bindings-wrapper .highcharts-stocktools-toolbar li.highcharts-disabled-btn > .highcharts-menu-item-btn {
  opacity: .5;
}

.highcharts-bindings-wrapper .highcharts-stocktools-toolbar li.highcharts-disabled-btn.highcharts-active {
  background: #f7f7f7;
}

.highcharts-bindings-wrapper .highcharts-stocktools-toolbar li.highcharts-disabled-btn .highcharts-menu-item-btn:hover {
  background-color: #0000;
}

.highcharts-bindings-wrapper li > span.highcharts-menu-item-btn {
  float: left;
  width: 100%;
  height: 100%;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 32px 32px;
  display: block;
}

.highcharts-submenu-wrapper li > span.highcharts-menu-item-btn {
  width: 40px;
}

.highcharts-bindings-wrapper li > span.highcharts-submenu-item-arrow {
  float: left;
  width: 10px;
  height: 100%;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 0;
}

.highcharts-bindings-wrapper li.highcharts-separator {
  height: 15px;
  width: 36px;
  pointer-events: none;
  background-color: #0000;
}

.highcharts-bindings-wrapper li.highcharts-separator > span.highcharts-menu-item-btn {
  width: 100%;
}

.highcharts-bindings-wrapper li.highcharts-active > span.highcharts-menu-item-btn, .highcharts-bindings-wrapper li > span.highcharts-menu-item-btn:hover, .highcharts-bindings-wrapper .highcharts-arrow-wrapper > div:hover, .highcharts-bindings-wrapper li.highcharts-active, .highcharts-toggle-toolbar:hover {
  background-color: #e6ebf5;
  transition: background-color .1s;
}

.highcharts-toggle-toolbar {
  cursor: pointer;
  width: 10px;
  height: 10px;
  background-color: #f7f7f7;
  background-size: cover;
  position: absolute;
}

.highcharts-hide {
  display: none;
}

.highcharts-bindings-wrapper li:hover, .highcharts-submenu-item-arrow:hover {
  background-color: #e6ebf5;
}

.highcharts-bindings-wrapper .highcharts-arrow-down, .highcharts-bindings-wrapper .highcharts-arrow-up {
  width: 50%;
  height: 20px;
  float: left;
}

li.highcharts-disabled-btn:hover, .highcharts-disabled-btn .highcharts-submenu-item-arrow:hover {
  background-color: #f7f7f7;
}

.highcharts-popup.highcharts-annotation-toolbar {
  height: 40px;
  width: auto;
  min-width: 0;
  padding-right: 40px;
  left: auto;
  right: 10%;
  overflow: hidden;
}

.highcharts-popup.highcharts-annotation-toolbar button {
  margin-top: 0;
}

.highcharts-popup.highcharts-annotation-toolbar > span {
  float: left;
  padding: 12px;
  display: block;
}

.highcharts-popup {
  color: #666;
  max-height: 90%;
  width: 75%;
  min-width: 300px;
  max-width: 600px;
  z-index: 100;
  background-color: #fff;
  font-family: Lucida Grande, Lucida Sans Unicode, Arial, Helvetica, sans-serif;
  font-size: .876em;
  display: none;
  position: absolute;
  top: 5%;
  left: 15%;
  overflow: hidden;
  box-shadow: 0 0 8px #3d3d3d4d;
}

.highcharts-popup div, .highcharts-popup span {
  box-sizing: border-box;
}

.highcharts-popup input, .highcharts-popup label, .highcharts-popup select {
  clear: both;
  float: left;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.highcharts-popup input {
  width: 100%;
  border: 1px solid #e6e6e6;
  border-radius: .3rem;
  padding: 5px;
}

.highcharts-popup-lhs-col, .highcharts-popup-rhs-col {
  height: 100%;
  float: left;
  overflow-y: auto;
}

.highcharts-popup-lhs-col.highcharts-popup-lhs-full {
  width: 100%;
  height: calc(100% - 104px);
  border: none;
  padding: 20px 20px 10px;
  overflow-x: hidden;
}

.highcharts-popup-lhs-col.highcharts-popup-lhs-full + .highcharts-popup-bottom-row {
  width: 100%;
}

.highcharts-popup-lhs-col {
  clear: both;
  width: 30%;
  border-right: 1px solid #e6e6e6;
}

.highcharts-popup-bottom-row {
  float: left;
  width: 100%;
  border-top: 1px solid #e6e6e6;
  padding: 0 20px 12px;
}

.highcharts-popup-rhs-col {
  width: 70%;
  height: calc(100% - 40px);
  padding: 20px;
}

.highcharts-popup-rhs-col-wrapper {
  width: 100%;
  overflow-x: hidden;
}

.highcharts-popup-rhs-col-wrapper h3 {
  margin-top: 0;
  padding-bottom: 0;
}

.highcharts-bindings-wrapper ul.highcharts-indicator-list, .highcharts-indicator-list {
  float: left;
  color: #666;
  height: calc(100% - 150px);
  width: 100%;
  margin: 0;
  padding: 15px 0;
  overflow-x: hidden;
}

.highcharts-indicator-list li {
  cursor: pointer;
  width: 100%;
  height: auto;
  word-break: break-all;
  box-sizing: border-box;
  margin: 0;
  padding: 5px 20px;
  overflow: hidden;
}

.highcharts-indicator-list li:hover {
  background-color: #e6e6e6;
}

.highcharts-tab-item {
  cursor: pointer;
  float: left;
  height: 40px;
  background-color: #f7f7f7;
  padding: 0 10px;
  line-height: 40px;
  display: block;
}

.highcharts-tab-item.highcharts-tab-item-active {
  background-color: #e6e6e6;
}

.highcharts-tab-item-content {
  float: left;
  height: 100%;
  width: 100%;
  border-top: 1px solid #e6e6e6;
  display: none;
  overflow: hidden;
}

.highcharts-tab-item-show {
  display: block;
}

.highcharts-popup-close {
  width: 40px;
  height: 40px;
  cursor: pointer;
  color: #333;
  background-position: 50%;
  background-repeat: no-repeat;
  padding: 10px;
  position: absolute;
  top: 0%;
  right: 0%;
}

.highcharts-popup-close:hover, .highcharts-popup button:hover, .highcharts-popup button.highcharts-annotation-edit-button:hover, .highcharts-popup button.highcharts-annotation-remove-button:hover {
  cursor: pointer;
  background-color: #e6e6e6;
}

.highcharts-popup button {
  float: right;
  color: #666;
  background: #f7f7f7;
  border: none;
  margin-top: 12px;
  margin-left: 5px;
}

.highcharts-popup button:first-child {
  margin-left: 0;
}

.highcharts-tab-disabled {
  color: #ccc;
}

.highcharts-popup button.highcharts-annotation-edit-button, .highcharts-popup button.highcharts-annotation-remove-button {
  width: 20px;
  height: 40px;
  padding: 20px;
}

.highcharts-popup button.highcharts-annotation-edit-button, .highcharts-popup button.highcharts-annotation-remove-button {
  text-indent: -9999px;
  background-position: 50%;
  background-repeat: no-repeat;
}

.highcharts-popup .highcharts-annotation-title {
  float: left;
  width: 100%;
  margin-bottom: 15px;
  font-size: 1.2em;
  font-weight: bold;
  display: block;
}

.highcharts-popup .highcharts-popup-main-title {
  border-bottom: 1px solid #e6e6e6;
  margin: 0;
  padding: 8px 0 6px 20px;
}

.highcharts-indicator-title {
  float: left;
  padding-bottom: 15px;
}

.highcharts-input-wrapper {
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  display: flex;
}

.highcharts-input-search-indicators-label {
  text-align: center;
  color: #000;
  margin-top: .5rem;
  font-weight: bold;
}

input.highcharts-input-search-indicators {
  width: 80%;
  float: none;
  border-color: #949494;
  margin: 0 auto;
}

.highcharts-popup a.clear-filter-button {
  color: #335cad;
  background-color: #fff;
  border: 1px solid #335cad;
  margin: 10px auto 0;
  display: none;
}

.highcharts-popup a.clear-filter-button:hover {
  color: #fff;
  cursor: pointer;
  background-color: #335cad;
}

.chart-type-item {
  display: flex;
}

.chart-type-item div {
  margin-right: 15px;
}

.chart-type-item div label {
  color: var(--font-grey3);
  font-size: var(--font-size-m);
}

.chart-type-item div select {
  font-size: var(--font-size-m);
  height: 30px;
  border: none;
  outline: none;
  margin-left: 2px;
}

@media screen and (min-width: 100px) and (max-width: 676px) {
  .chart-type-item div label, .chart-type-item div select {
    font-size: var(--font-size-xs);
  }
}

:root {
  --font-orange: #ff6b01;
  --font-black: #000;
  --font-white: #fff;
  --font-yellow: #ffc628;
  --font-grey: #8e8e8e;
  --font-grey2: #9b9b9b;
  --font-grey3: #a9a9a9;
  --font-Darkgrey: #4a4848;
  --font-green: #56c876;
  --font-blue: #487fd9;
  --font-red: #e05353;
  --font-black2: #252b42;
  --ebony-clay: #252b42;
  --bg-orange: #ff6b00;
  --bg-yellow: #ffc628;
  --bg-white: #fff;
  --bg-black: #000;
  --bg-grey: #626262;
  --bg-grey3: #ccc;
  --bg-light-grey: #f8f8f8;
  --bg-green: #56c876;
  --bg-red: #e05353;
  --bg-grey-2: #f2f2f2;
  --bg-light-grey-2: #eee;
  --bg-dark-grey: #bdbaba;
  --bg-marino: #4772a0;
  --bg-light-grey3: #dadada;
  --bg-white3: #e5e5e512;
  --mt-orange: #ff6b01;
  --border-orange-1: 1px #ea9e68 solid;
  --border-grey-1: 1px #ccc solid;
  --border-black-1: 1px #000 solid;
  --border-black-2: 2px #000000b3 solid;
  --border-grey-1: 1px #ccc solid;
  --border-red-1: 1px #e05353 solid;
}

.account-metrics {
  justify-content: space-between;
  margin-top: 20px;
  padding: 20px;
  display: flex;
}

.account-metrics .metrics-item {
  text-align: center;
  flex: 1;
  padding: 0 5px;
}

.account-metrics .metrics-item label, .account-metrics .metrics-item div span:nth-child(2) {
  font-size: var(--font-size-15);
  color: var(--font-grey);
  white-space: nowrap;
}

.account-metrics .metrics-item div {
  font-size: var(--font-size-xl);
  color: var(--font-black);
  font-weight: var(--font-weight-600);
  text-align: center;
  width: 100%;
  margin-bottom: 15px;
  padding: 10px 0;
}

.account-metrics .metrics-item div span {
  display: block;
}

.account-metrics .metrics-item .green-value {
  color: var(--font-green);
}

.account-metrics .metrics-item .red-value {
  color: var(--font-red);
}

@media screen and (min-width: 100px) and (max-width: 1020px) {
  .view-container {
    margin-right: 0;
  }
}

@media screen and (min-width: 100px) and (max-width: 676px) {
  .account-metrics {
    padding: 20px 5px;
  }

  .account-metrics .metrics-item div {
    font-size: var(--font-size-11);
  }

  .account-metrics .metrics-item label {
    font-size: 8px;
  }

  .account-metrics .metrics-item div span:nth-child(2) {
    font-size: var(--font-size-s);
  }

  body .wrapper-container {
    padding: 0 5px;
  }
}

.account-details {
  flex-wrap: wrap;
  padding: 10px 15px;
  display: flex;
}

.account-details .detail-item {
  width: calc(33% - 10px);
  text-align: center;
  padding: 15px 5px;
}

.account-details .detail-item label {
  font-size: var(--font-size-s);
  color: var(--font-grey);
  display: block;
  position: relative;
}

.account-details .detail-item div {
  font-size: var(--font-size-xl);
  color: var(--font-Darkgrey);
  font-weight: var(--font-weight-600);
  padding-top: 5px;
  display: block;
}

.account-details .detail-item span {
  width: 13px;
  height: 13px;
  background: var(--bg-light-grey2);
  color: var(--font-white);
  font-size: var(--font-size-xs);
  text-align: center;
  border-radius: 100%;
  margin-left: 5px;
  position: absolute;
}

@media screen and (min-width: 100px) and (max-width: 676px) {
  .account-details .detail-item div, .account-details .detail-item label, .trade-info .stock-desc span, .trade-info .stock-detail div span {
    font-size: var(--font-size-xs);
  }
}

div[modal-size="medium"] {
  max-width: 688px;
  width: 98%;
  font-family: var(--robotoregular);
}

div[modal-size="small"] {
  max-width: 450px;
  width: 75%;
  font-family: var(--robotoregular);
}

div[modal-size="large"] {
  max-width: 940px;
  width: 85%;
  font-family: var(--robotoregular);
}

.modal-body {
  max-height: 70vh;
  overflow: auto;
}

.modal-overlay {
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: #0006;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-root {
  z-index: 3;
  background: var(--bg-white);
  border: var(--border-grey-1);
  border-radius: 20px;
  padding: 5px 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 2px 8px #636363e0;
}

.modal-header-text {
  font-size: var(--font-size-xxl);
  font-weight: var(--font-weight-600);
  color: var(--font-black);
  margin: 10px 0 14px;
}

.modal-header-text img {
  width: 29px;
  margin-right: 10px;
  position: relative;
  top: 6px;
}

@keyframes animatetop {
  from {
    opacity: 0;
    top: -300px;
  }

  to {
    opacity: 1;
    top: 0;
  }
}

.modal-close {
  float: right;
  font-size: var(--font-size-36);
  font-weight: var(--font-weight-400);
  color: var(--font-grey);
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  top: 15px;
  right: 20px;
}

.modal-close:hover {
  color: #000;
}

.modal-root header {
  border-bottom: var(--border-black-1);
  margin-right: 25px;
}

.modal-footer {
  text-align: center;
  padding: 30px 0;
}

@media screen and (min-width: 100px) and (max-width: 840px) {
  div[modal-size="medium"] {
    width: 90%;
    padding: 10px 5px;
  }
}

@media screen and (min-width: 100px) and (max-width: 676px) {
  .modal-header-text {
    font-size: var(--font-size-m);
  }
}

.width-200 {
  width: 200px;
}

.modal-body .account-details .detail-item {
  width: calc(33% - 4px);
  padding: 20px 2px;
}

.main-container {
  flex: 1;
  margin-right: 15px;
}

.right-side-bar {
  background: var(--bg-light-grey);
  width: 30%;
  min-width: 400px;
}

@media screen and (min-width: 100px) and (max-width: 1020px) {
  .main-container {
    height: 300px;
    margin-right: 0;
  }

  .right-side-bar {
    width: 100%;
    min-width: auto;
    background: none;
    margin-top: 20px;
  }
}

.view-container {
  flex: 1;
}

.border-grey-1 {
  border: var(--border-grey-1);
}

.margin-right-20 {
  margin-right: 20px;
}

.text-right {
  text-align: right;
}

.fullscreen-icon {
  margin-top: 20px;
}

.fullscreen-icon img {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.list-wrapper {
  max-width: 100%;
  font-family: var(--font-family-roboto-regular);
  font-weight: var(--font-weight-400);
  margin: 30px 0;
  display: block;
}

.list tr:not(.separator):nth-child(2n) {
  background: #c4c4c433;
}

.list tr:not(.separator):nth-child(2n+1) {
  background: #fff;
}

.list-wrapper .list .separator td {
  border-top: 1px solid #ccc;
  padding: 0;
}

.list {
  width: 100%;
  border-spacing: 0;
}

.list tr th {
  text-align: left;
  vertical-align: top;
  font-weight: var(--font-weight-400);
  color: var(--font-grey);
  font-size: var(--font-size-s);
  padding: 5px;
}

.list tr th img {
  width: 7px;
}

.list td {
  color: var(--font-black);
  font-size: var(--font-size-m);
  font-family: var(--font-family-roboto);
  padding: 18px 5px;
}

.list td .exapnder img {
  width: 25px;
  position: relative;
  top: 4px;
}

.list .multi-line-cell span:last-child {
  font-size: var(--font-size-13);
  padding-top: 5px;
  display: block;
}

.list .green-value {
  color: var(--font-green);
}

.list .red-value {
  color: var(--font-red);
}

.list .header-notation span:last-child {
  display: block;
}

.list .symbol-n-delta.red-value {
  width: 100px;
  background: #e0535340;
  border-radius: 12px;
  justify-content: space-between;
  padding: 10px;
  display: flex;
}

.list .symbol-n-delta.green-value {
  width: 100px;
  background: #63dc8540;
  border-radius: 12px;
  justify-content: space-between;
  padding: 10px;
  display: flex;
}

.list .symbol-n-delta span:first-child {
  font-weight: var(--font-weight-700);
}

.list .symbol-n-delta span:last-child {
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-s);
  font-family: var(--font-family-roboto);
  opacity: .75;
}

.list .account-status span {
  margin-right: 5px;
  position: relative;
  top: 2px;
}

.list .name-n-timestamp span:last-child {
  opacity: .6;
}

.list-wrapper.sidebar-widget table.list tr {
  background: none;
}

.sidebar-widget .list .stock-info span label:last-child, .list .stock-info span label:last-child {
  opacity: .6;
  font-size: var(--font-size-13);
  width: 120px;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-top: 5px;
  display: block;
  overflow: hidden;
}

.sidebar-widget .list .stock-icon {
  margin-right: 5px;
}

.sidebar-widget .list .stock-info {
  display: flex;
}

.list-wrapper .list .order-datetime {
  font-weight: var(--font-weight-400);
  color: var(--font-grey);
  font-size: var(--font-size-s);
}

.processBar {
  z-index: 1;
  width: 100px;
  height: 100px;
  border: 16px solid #f3f3f3;
  border-top-color: #3498db;
  border-radius: 50%;
  margin: -76px 0 0 -76px;
  animation: spin 2s linear infinite;
  position: relative;
  top: 50%;
  left: 50%;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.animate-bottom {
  animation-name: animatebottom;
  animation-duration: 1s;
  position: relative;
}

@keyframes animatebottom {
  from {
    opacity: 0;
    bottom: -100px;
  }

  to {
    opacity: 1;
    bottom: 0;
  }
}

.list > tbody {
  height: 100px;
}

.list.loading tbody {
  position: relative;
}

.list .dropdown-menu {
  float: left;
  position: relative;
}

.list .dropdown-menu .dropdown-dots-icon {
  cursor: pointer;
  height: 20px;
}

.list .dropdown-menu-item {
  background: var(--bg-white);
  min-width: 100px;
  z-index: 1;
  width: 160px;
  border: var(--border-grey-1);
  padding: 0 10px;
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
  overflow: auto;
  box-shadow: 0 8px 16px #0003;
}

.list .dropdown-menu-item a {
  color: var(--font-black);
  font-size: var(--font-size-s);
  border-bottom: var(--border-black-1);
  cursor: pointer;
  padding: 6px 5px 11px;
  text-decoration: none;
  display: block;
}

.list .dropdown-menu-item a span {
  opacity: .6;
}

.list .dropdown-menu-item a:hover span {
  opacity: 1;
}

.list .dropdown-menu-item a:last-child {
  border-bottom: none;
}

.list .show {
  display: block;
}

.list .dropdown-menu-icon {
  width: 22px;
  position: relative;
  top: 6px;
  left: -5px;
}

@media screen and (min-width: 100px) and (max-width: 676px) {
  .sidebar-widget .list .stock-icon img {
    width: 22px;
  }

  .list tr th {
    font-size: var(--font-size-8);
  }

  .list td {
    font-size: var(--font-size-9);
    padding: 12px 5px;
  }

  .list .multi-line-cell span:last-child {
    font-size: var(--font-size-8);
  }

  .list tr th, .list tr td {
    padding: 5px 2px;
  }

  .list .account-status {
    display: flex;
  }

  .list .account-status img {
    width: 7px;
  }

  .list tr th img {
    width: 5px;
  }

  .list td .exapnder img {
    width: 15px;
  }

  .list .symbol-n-delta span:last-child {
    font-size: var(--font-size-7);
  }

  .list .symbol-n-delta.red-value, .list .symbol-n-delta.green-value {
    width: 65px;
  }

  .list .symbol-n-delta.green-value {
    padding: 5px;
  }

  .list-wrapper .list .dropdown-dots-icon {
    width: 3px;
    height: initial;
    background-size: 100% 100%;
  }

  .list .symbol-n-delta.red-value {
    padding: 5px;
  }

  .list-wrapper {
    margin: 15px 0;
  }

  .list .dropdown-menu-icon {
    width: 18px;
  }

  .list .dropdown-menu-item a {
    font-size: var(--font-size-xs);
    padding: 3px 4px 9px;
  }
}

:root {
  --tooltip-text-color: white;
  --tooltip-background-color: black;
  --tooltip-margin: 30px;
  --tooltip-arrow-size: 6px;
}

.pophover-wrapper {
  display: inline-block;
  position: relative;
}

.pophover {
  font-size: var(--font-size-s);
  z-index: 1;
  white-space: nowrap;
  border-radius: 4px;
  padding: 15px;
  line-height: 1;
  position: absolute;
  left: 50%;
}

.pophover.top {
  top: calc(var(--tooltip-margin)  * -1);
}

.pophover.top:before {
  border-top-color: var(--tooltip-background-color);
  top: 100%;
}

.pophover.right {
  background: var(--bg-white);
  border: var(--border-grey-1);
  width: 300px;
  top: 50%;
  left: 100%;
  transform: translateX(0)translateY(-50%);
  box-shadow: 0 8px 16px #0003;
}

.pophover.bottom {
  bottom: calc(var(--tooltip-margin)  * -1);
}

.pophover.left {
  left: auto;
  right: calc(100% + var(--tooltip-margin) );
  top: 50%;
  transform: translateX(0)translateY(-50%);
}

.pophover-chart-header {
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-700);
  border-bottom: var(--border-black-1);
  justify-content: space-between;
  padding-bottom: 6px;
  display: flex;
}

.pophover-chart-header span:last-child {
  color: var(--font-grey2);
  font-weight: normal;
  font-family: var(--font-family-roboto-regular);
}

.pophover-chart-info {
  color: var(--font-grey2);
  font-family: var(--font-family-roboto-regular);
  padding-top: 10px;
}

.pophover-chart-footer {
  justify-content: end;
  padding-top: 10px;
  display: flex;
}

.pophover-chart-footer a {
  color: var(--font-blue);
  cursor: pointer;
  margin-left: 10px;
  text-decoration: underline;
}

.tab {
  height: auto;
  color: #ff6b01;
}

.tab ul.nav {
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
  position: relative;
}

.sm-tab .tab ul.nav {
  justify-content: left;
}

.sm-tab .tab ul.nav li {
  flex: none;
}

.sm-tab .tab .outlet {
  border: none;
  border-top: var(--border-black-1);
}

.tab ul.nav li {
  color: #8e8e8e;
  font-size: var(--font-size-s);
  text-transform: uppercase;
  font-weight: var(--font-weight-700);
  cursor: pointer;
  border: var(--border-black-1);
  background: var(--bg-grey-2);
  border-bottom: none;
  flex: 1;
  align-items: center;
  margin-right: 10px;
  padding: 12px 15px;
  list-style: none;
  transition: all .7s;
  display: flex;
}

.tab ul.nav li:last-child {
  margin-right: 0;
}

.tab-title {
  flex: 1;
}

.tab .outlet {
  border: var(--border-black-1);
}

.tab ul.nav li.active {
  background: var(--bg-white);
  color: var(--font-orange);
  border-radius: 12px 0 0;
  position: relative;
  top: 1px;
}

.active .right-info {
  color: var(--font-black);
}

.right-info {
  font-size: var(--font-size-m);
}

.tab-group-info {
  color: var(--font-Darkgrey);
  font-size: var(--font-size-s);
  font-family: var(--font-family-roboto);
  padding: 0 40px;
}

.tab-icon {
  width: 45px;
  height: 27px;
  margin-right: 10px;
}

.tab-icon img {
  width: 27px;
}

@media screen and (min-width: 100px) and (max-width: 840px) {
  .tab-group-info {
    padding: 0 10px;
  }
}

@media screen and (min-width: 100px) and (max-width: 676px) {
  .tab ul.nav li.active {
    border-radius: 10px 0 0;
  }

  .tab-group-info {
    position: absolute;
    top: -24px;
  }

  .tab ul.nav li {
    font-size: var(--font-size-8);
    padding: 7px;
  }

  .right-info {
    font-size: var(--font-size-8);
  }

  .tab-icon img {
    width: 16px;
  }

  .tab-icon {
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }
}

.large-group-info {
  font-size: var(--font-size-xxl);
  color: var(--font-black);
  font-family: var(--font-family-roboto);
  font-weight: var(--font-weight-700);
  padding: 0 15px 0 0;
  display: flex;
}

.large-group-info img {
  width: 27px;
  margin-right: 5px;
}

.large-group-info svg {
  width: 27px;
  height: 27px;
  margin-right: 5px;
}

.noalpacadialog-model:before {
  content: "";
  width: 300px;
  height: 300px;
  z-index: -1;
  opacity: .8;
  background: url("question-bg.7d95b1b6.png") 0 0 / 100% 100%;
  position: absolute;
  top: 70px;
  right: 0;
}

.noalpacadialog-info {
  color: var(--font-black);
  max-width: 65%;
}

.noalpacadialog-info div {
  line-height: 25px;
}

.noalpacadialog-footer {
  max-width: 65%;
  text-align: left;
  justify-content: space-between;
  padding-top: 30px;
  display: flex;
}

.noalpacadialog-footer a {
  margin-left: 40px;
}

@media screen and (min-width: 100px) and (max-width: 676px) {
  .noalpacadialog-model:before {
    opacity: .3;
  }

  .noalpacadialog-footer, .noalpacadialog-info {
    max-width: 100%;
  }
}

.width-200 {
  width: 200px;
}

.modal-body .account-details .detail-item {
  width: calc(33% - 4px);
  padding: 20px 2px;
}

:root {
  --font-size-l: 18px;
  --font-size-m: 16px;
  --font-size-s: 14px;
  --font-size-13: 13px;
  --font-size-15: 15px;
  --font-size-xl: 20px;
  --font-size-10: 10px;
  --font-size-xs: 12px;
  --font-size-xxl: 24px;
  --font-size-xxxl: 30px;
  --font-size-36: 36px;
  --font-size-28: 28px;
  --font-size-26: 26px;
  --font-size-40: 40px;
  --font-size-50: 50px;
  --font-size-9: 9px;
  --font-size-7: 7px;
  --font-size-8: 8px;
  --font-size-11: 11px;
  --font-weight-300: 300;
  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-600: 600;
  --font-weight-700: 700;
  --font-weight-800: 800;
  --font-weight-900: 900;
  --font-family-rubik: "Rubik", Helvetica;
  --font-family-inter: "Inter", Helvetica;
  --font-family-roboto-regular: "robotoregular";
  --font-family-roboto: "Roboto";
  --font-family-montserrat: "Montserrat", Helvetica;
}

@font-face {
  font-family: robotoregular;
  src: url("roboto-regular-webfont.503dd254.woff2") format("woff2"), url("roboto-regular-webfont.d11ad91a.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  src: url("Roboto-Medium.6a167edf.eot");
  src: url("Roboto-Medium.6a167edf.eot#iefix") format("embedded-opentype"), url("Roboto-Medium.269551ff.woff2") format("woff2"), url("Roboto-Medium.5424fac7.woff") format("woff"), url("Roboto-Medium.7f5af689.ttf") format("truetype"), url("Roboto-Medium.11f23ff3.svg#Roboto-Medium") format("svg");
  font-style: normal;
  font-display: swap;
}

.card-main-container {
  min-height: 350px;
  font-size: var(--font-size-m);
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 15px 0;
  display: flex;
}

.card {
  padding: 15px;
}

.card-header {
  border-bottom: var(--border-black-1);
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
  display: flex;
}

.card-title {
  font-size: var(--font-size-xxl);
  color: var(--font-black);
  font-family: var(--font-family-roboto);
  margin: 0;
  padding: 0;
}

.card-title img {
  width: 27px;
  height: 27px;
  position: relative;
  top: 4px;
}

.card-title svg {
  width: 31px;
  height: 31px;
  position: relative;
  top: 4px;
}

.card-tools {
  display: flex;
}

.card-tools-text {
  color: var(--font-green);
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-m);
  margin: 5px 0 0 5px;
}

.card-tools-icon {
  width: 28px;
}

.card-tools-icon img {
  width: 100%;
}

.card-tools-text {
  color: var(--font-green);
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-m);
  margin: 5px 0 0 5px;
}

.card-tools-icon {
  width: 28px;
}

.card-tools-icon img {
  width: 100%;
}

.card-header-v2 {
  background: var(--bg-light-grey-2);
  border-bottom: none;
  padding: 10px 15px;
}

.card-header-v2 .card-title {
  font-family: var(--font-family-inter);
  font-weight: var(--font-weight-300);
}

@media screen and (min-width: 100px) and (max-width: 1020px) {
  .card-main-container {
    display: block;
  }
}

@media screen and (min-width: 100px) and (max-width: 676px) {
  .card-main-container .card {
    padding: 15px 10px;
  }

  .card-main-container .padding-top-0 {
    padding-top: 0;
  }

  .card-title {
    font-size: var(--font-size-s);
  }

  .card-title img {
    width: 16px;
  }

  .card-tools-text {
    font-size: var(--font-size-9);
    margin-top: 3px;
  }

  .card-tools-icon {
    width: 17px;
  }
}

body {
  font-size: var(--font-size-s);
  font-family: var(--font-family-inter);
  background: var(--bg-white);
  color: var(--font-black);
  margin: 0;
  padding: 0;
}

.header-container {
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  display: flex;
}

.header-errors {
  color: red;
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-l);
  font-weight: 100;
}

.wrapper-container {
  margin-top: 115px;
  padding: 0 20px;
}

::-webkit-input-placeholder {
  color: var(--font-grey3);
}

:-ms-input-placeholder {
  color: var(--font-grey3);
}

::placeholder {
  color: var(--font-grey3);
  font-weight: normal;
  font-size: var(--font-size-l);
  font-family: var(--font-family-inter);
}

h1 {
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-36);
  font-weight: 100;
}

.padding-left-zero {
  padding-left: 0 !important;
}

.padding-top-20 {
  padding-top: 20px;
}

.padding-bttom-10 {
  padding-bottom: 10px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-right-30 {
  margin-right: 30px;
}

.padding-right-15 {
  padding-right: 15px;
}

.padding-20 {
  padding: 20px;
}

.padding-0 {
  padding: 0;
}

.padding-top-0 {
  padding-top: 0;
}

.button-sm {
  font-weight: 600;
  font-size: var(--font-size-s);
  font-family: var(--font-family-rubik);
  cursor: pointer;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: none;
  border-radius: 50px;
  outline: none;
  align-items: center;
  padding: 15px 30px;
}

.btn-orange {
  background: var(--bg-orange);
  color: var(--font-white);
}

.btn-orange-border {
  color: var(--font-orange);
  border: var(--border-orange-1);
  background: none;
}

.bg-green {
  width: 25px;
  height: 25px;
  background: var(--bg-green);
  text-align: center;
  border-radius: 100%;
}

.bg-grey {
  background: var(--bg-light-grey);
}

.blank-container {
  height: 250px;
  text-align: center;
  clear: both;
  padding-top: 20px;
}

.hide {
  display: none;
}

.link-text {
  color: var(--font-blue);
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-600);
  cursor: pointer;
  text-decoration: underline;
}

.border-black-1 {
  border: var(--border-black-1);
}

.pagination select {
  border: var(--border-grey-1);
  background: var(--bg-white);
  color: var(--font-black);
  width: 90px;
  border-radius: 5px;
  outline: none;
  margin-top: 5px;
  padding: 8px 5px;
}

.search-box-item {
  z-index: 1;
  flex: 1;
  padding: 0 5px;
  position: relative;
}

.search-box-item img {
  width: 18px;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.search-box-item label {
  font-size: var(--font-size-s);
  color: var(--font-grey2);
  white-space: nowrap;
}

.search-box-item input[type="text"] {
  width: calc(100% - 63px);
  border: var(--border-black-2);
  height: 43px;
  font-size: var(--font-size-l);
  background: var(--bg-white);
  color: var(--font-black);
  border-radius: 10px;
  margin-top: 5px;
  padding: 5px 50px 5px 10px;
}

.border-grey input[type="text"] {
  border: var(--border-grey-1);
  background: var(--bg-white);
  outline: none;
}

.grid-icon-info {
  display: flex;
}

.grid-icon-info .grid-icon {
  margin-right: 5px;
}

.grid-icon-info .grid-icon img {
  width: 22px;
}

.grid-icon-info span label:last-child, .portfolio-name-column span label:not(:nth-child(1)) {
  opacity: .6;
  font-size: var(--font-size-13);
  text-overflow: ellipsis;
  padding-top: 5px;
  display: block;
  overflow: hidden;
}

.portfolio-name-column .grid-icon {
  margin-right: 5px;
  display: grid;
}

.select-in-header select {
  font-size: var(--font-size-m);
  height: 30px;
  border: none;
  outline: none;
  margin-left: 2px;
}

.multi-select-item {
  z-index: 1;
  flex: 1;
  padding: 0 5px;
  position: relative;
}

.multi-select-item .multi-select-icon {
  width: 18px;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.multi-select-item .multi-select-label {
  font-size: var(--font-size-s);
  color: var(--font-grey2);
  white-space: nowrap;
}

.refresh-button {
  background: var(--bg-orange);
  border: var(--border-orange-1);
  height: 40px;
  cursor: pointer;
  color: var(--font-white);
  border-radius: 11px;
  padding: 0 5px;
  position: relative;
}

.refresh-button:hover {
  background: var(--bg-white);
  color: var(--font-orange);
}

.refresh-button svg:hover {
  fill: var(--font-orange);
}

@media screen and (min-width: 100px) and (max-width: 676px) {
  .search-box-item input[type="text"] {
    height: 35px;
    font-size: var(--font-size-m);
  }

  ::placeholder {
    font-size: var(--font-size-s);
  }

  .grid-icon-info .grid-icon {
    margin-right: 2px;
  }

  .grid-icon-info span label:last-child {
    font-size: var(--font-size-9);
  }

  .search-box-item img {
    bottom: 13px;
  }

  .large-group-info {
    position: initial;
    font-size: var(--font-size-xs);
  }

  .large-group-info img {
    width: 15px;
  }

  h1 {
    font-size: var(--font-size-m);
  }

  .button-sm {
    font-size: var(--font-size-xs);
    padding: 10px 15px;
  }

  .header-container-title {
    padding: 10px;
  }

  .card-main-container .card.no-padding-mobile {
    padding: 0;
  }

  .select-in-header select, .select-in-header label {
    font-size: var(--font-size-xs);
  }
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.clear {
  clear: both;
}

.text-center {
  text-align: center;
}

a {
  text-decoration: none;
}

.text-right {
  text-align: right;
}

.no-flex {
  flex: none;
}

.top-header {
  width: calc(100% - 40px);
  background: var(--bg-white);
  height: 70px;
  z-index: 2;
  align-items: center;
  margin: 0;
  padding: 13px 20px;
  display: flex;
  position: fixed;
  top: 0;
}

.top-header:after {
  content: "";
  height: 7px;
  width: 100%;
  background: var(--bg-orange);
  background: linear-gradient(90deg, #ff6b00 0%, #fcd8d5 100%, #090979 100%);
  margin-top: 96px;
  position: absolute;
  left: 0;
}

.top-navbar {
  flex: 1;
  margin: 0;
  padding-left: 25px;
}

.top-navbar ul {
  justify-content: right;
  margin: 0;
  padding: 0;
  display: flex;
}

.top-navbar ul li {
  align-items: center;
  margin: 0 20px;
  list-style: none;
  display: flex;
}

.top-navbar ul li a {
  color: var(--font-black);
  font-size: var(--font-size-xl);
  cursor: pointer;
  font-weight: var(--font-weight-400);
  padding: 0;
  text-decoration: none;
  display: block;
}

.top-navbar ul li a:hover {
  color: var(--font-orange);
}

.menu-search_icon {
  height: 28px;
  cursor: pointer;
  width: 28px;
}

.menu-message {
  height: 44px;
  width: 44px;
  position: relative;
}

.message-icon {
  height: 35px;
  width: 36px;
  position: absolute;
  top: 5px;
  left: 0;
}

.message-icon-text {
  background: var(--bg-orange);
  height: 18px;
  color: var(--font-white);
  min-width: 18px;
  text-align: center;
  border-radius: 100%;
  font-size: 13px;
  line-height: 18px;
  position: absolute;
  top: -2px;
  left: 27px;
}

.toggle-switcher {
  display: none;
}

.toggle-switcher:checked + .toggle-switch {
  background: var(--bg-orange);
}

.toggle-switcher:checked + .toggle-switch .toggle-label-background:before {
  content: "Bright";
  right: 40px;
}

.toggle-switcher:checked + .toggle-switch:before {
  background: var(--bg-white) url("sun-icon.c8f52093.png") no-repeat 2px 3px;
  animation-name: switch;
  animation-duration: .35s;
  animation-fill-mode: forwards;
}

.toggle-switch {
  width: 90px;
  height: 40px;
  background: var(--bg-grey);
  border-radius: 100px;
  transition: all .35s ease-in;
  display: flex;
  position: relative;
}

.toggle-label-background:before {
  content: "Dark";
  font-weight: var(--font-weight-700);
  color: var(--font-white);
  border-radius: 5px;
  transition: all .15s ease-in;
  position: absolute;
  top: 12px;
  right: 15px;
}

.toggle-switch:before {
  content: "";
  width: 30px;
  height: 30px;
  z-index: 10;
  background: var(--bg-white) url("moon-icon.8795e29f.png") no-repeat 6px 6px;
  border-radius: 82px;
  transition: all .2s ease-in;
  animation-name: reverse;
  animation-duration: .2s;
  animation-fill-mode: forwards;
  position: absolute;
  top: 5px;
  left: 4px;
}

@keyframes switch {
  0% {
    left: 4px;
  }

  60% {
    width: 30px;
    left: 4px;
  }

  100% {
    width: 30px;
    left: 55px;
  }
}

@keyframes reverse {
  0% {
    width: 40px;
    left: 50px;
  }

  60% {
    width: 40px;
    left: 45px;
  }

  100% {
    left: 4px;
  }
}

.user-panel {
  background: var(--bg-orange);
  height: 65px;
  min-width: 65px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
}

.user-panel-name {
  color: var(--font-white);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: var(--font-weight-900);
}

.mobile-menu-overlay:before {
  width: 100%;
  height: 100%;
  content: "";
  background: #0000006b;
  display: none;
  position: fixed;
  top: 93px;
  left: 0;
}

.mobile-view-profile .mobile-sign-out {
  color: var(--font-white);
  text-align: right;
  display: none;
}

.mobile-navbar, .mobile-menu-search input, .top-navbar .mobile-menu-text {
  display: none;
}

.message-white-icon {
  height: 35px;
  width: 36px;
  position: absolute;
  top: 5px;
  left: 0;
}

.top-navbar ul .show-in-mobile, .mobile-theme, .mobile-menu-close {
  display: none;
}

.mobile-menu-overlay .mobile-menu-close {
  display: block;
}

.user-navigation-menu {
  display: none;
}

.mobile-view-profile.user-navigation {
  display: inline;
  position: relative;
}

.mobile-view-profile.user-navigation.connect {
  display: flex;
}

.user-navigation .user-navigation-menu {
  background: var(--bg-white);
  z-index: 11;
  border: var(--border-grey-1);
  width: 150px;
  border-radius: 7px;
  padding: 10px;
  display: block;
  position: absolute;
  top: 76px;
  left: -105px;
  box-shadow: 0 3px 8px #0000003d;
}

.user-navigation .user-navigation-menu ul {
  display: block;
}

.user-navigation .user-navigation-menu ul li {
  margin: 0 10px;
}

.user-navigation .user-navigation-menu ul li a {
  font-size: var(--font-size-xl);
  color: var(--font-black);
  padding: 10px 0;
  text-decoration: none !important;
}

.user-navigation .user-navigation-menu:after, .user-navigation .user-navigation-menu:before {
  content: "";
  height: 0;
  width: 0;
  pointer-events: none;
  border: solid #0000;
  position: absolute;
  bottom: 100%;
  right: 20px;
}

.user-navigation .user-navigation-menu:before {
  border-width: 10px;
  border-color: #c2e1f500 #c2e1f500 #ccc;
}

.top-navbar ul li.mobile-view-profile.connect a {
  align-items: center;
  display: flex;
}

@media screen and (min-width: 1019px) and (max-width: 1220px) {
  .top-navbar ul {
    justify-content: space-between;
  }

  .top-navbar ul li {
    margin: 0;
  }
}

@media screen and (min-width: 100px) and (max-width: 1020px) {
  .mobile-menu-overlay:before, .mobile-navbar {
    display: block;
  }

  .top-navbar {
    background: var(--bg-orange);
    width: 100%;
    z-index: 1;
    height: 100vh;
    padding-left: 0;
    transition-duration: .5s;
    position: fixed;
    top: -100vh;
    left: 0;
    transform: translateY(0);
  }

  .top-navbar ul li {
    border-top: 1px solid #ffffff7a;
    margin: 0 30px;
  }

  .top-navbar ul li a {
    width: 100%;
    color: var(--font-white);
    font-size: var(--font-size-m);
    padding: 18px 0;
  }

  .top-navbar ul li a:hover {
    color: var(--font-white);
  }

  .top-navbar ul {
    display: block;
  }

  .top-header {
    justify-content: space-between;
  }

  .mobile-view-menu {
    padding-top: 200px;
    top: 0;
  }

  .user-panel {
    background: var(--bg-white);
    width: 65px;
  }

  .user-panel-name {
    color: var(--font-orange);
  }

  .mobile-view-profile .mobile-sign-out {
    display: inline-block;
  }

  .top-navbar ul .mobile-view-profile {
    width: calc(100% - 60px);
    border-top: none;
    position: absolute;
    top: 20px;
  }

  .top-navbar ul li.mobile-view-profile a {
    width: 49%;
    border-bottom: none;
    text-decoration: underline;
    display: inline-block;
  }

  li.mobile-view-profile.connect {
    position: relative;
  }

  .top-navbar .mobile-menu-search {
    width: calc(100% - 60px);
    border-top: none;
    position: absolute;
    top: 130px;
  }

  .mobile-menu-search input {
    width: 100%;
    background: var(--bg-white);
    height: 45px;
    color: var(--font-black);
    font-size: var(--font-size-xl);
    border: none;
    border-radius: 10px;
    outline: none;
    padding: 0 15px;
    display: block;
  }

  .menu-search_icon {
    position: absolute;
    top: 10px;
    right: 20px;
  }

  .top-navbar .mobile-menu-text {
    color: var(--font-white);
    font-size: var(--font-size-m);
    font-weight: var(--font-weight-400);
    flex: 1;
    padding: 20px 0;
    display: block;
  }

  .message-icon {
    display: none;
  }

  .message-white-icon {
    display: block;
  }

  .message-icon-text {
    background: var(--bg-white);
    color: var(--bg-orange);
    bottom: 3px;
    top: inherit;
    width: 14px;
    min-width: auto;
    height: 14px;
    line-height: 15px;
    font-size: var(--font-size-10);
    left: 20px;
  }

  .menu-search_icon {
    width: 20px;
    height: 20px;
  }

  .top-navbar ul .show-in-mobile {
    display: block;
  }

  .mobile-menu-overlay .mobile-navbar {
    z-index: 11;
    width: 35px;
    height: 35px;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .mobile-menu-overlay .mobile-menu-show {
    display: none;
  }

  .user-navigation .user-navigation-menu {
    top: 95px;
    left: 0;
  }

  .user-navigation .user-navigation-menu ul li a {
    font-size: var(--font-size-m);
    width: 100%;
  }

  .user-navigation .user-navigation-menu:after, .user-navigation .user-navigation-menu:before {
    right: 130px;
  }
}

@media screen and (min-width: 100px) and (max-width: 676px) {
  .top-navbar ul li a.link-active {
    color: var(--font-white);
  }

  .company-logo a span {
    font-size: var(--font-size-m);
    font-weight: var(--font-weight-800);
  }

  .top-header:after {
    margin-top: 60px;
  }

  main .wrapper-container {
    margin-top: 80px;
  }

  .top-header {
    width: calc(100% - 20px);
    height: 50px;
    padding: 5px 10px;
  }

  .company-logo img {
    height: 30px;
    width: 30px;
  }

  .mobile-navbar .mobile-menu-show {
    width: 30px;
  }
}

.link-active {
  color: var(--font-orange) !important;
}

.mobile-link-active {
  color: var(--font-black) !important;
}

.footer {
  width: 100%;
  background: var(--bg-light-grey);
  margin: 0;
  padding: 20px 0 0;
  box-shadow: 0 -4px 10px #0000001a;
}

.footer-inner {
  margin: auto;
  padding: 20px 40px;
  display: flex;
}

.footer-col {
  flex: 1;
}

.footer-header {
  color: var(--font-orange);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: var(--font-weight-700);
  padding-bottom: 20px;
}

.footer-menu, .footer-menu ul {
  margin: 0;
  padding: 0;
}

.footer-menu ul li {
  margin-top: 15px;
  list-style: none;
}

.footer-menu ul li a {
  color: var(--font-black);
  font-size: var(--font-size-m);
  padding: 2px 0;
  text-decoration: none;
}

.footer-menu ul li a:hover {
  color: var(--font-orange);
}

.footer-address {
  margin: 0;
}

.footer-address ul {
  margin: 0;
  padding: 0;
}

.footer-address ul li {
  color: var(--font-black);
  font-size: var(--font-size-m);
  margin-top: 15px;
  list-style: none;
}

.footer-contact {
  margin: 0;
  padding-top: 30px;
}

.footer-contact ul {
  margin: 0;
  padding: 0;
}

.footer-contact ul li {
  margin-top: 15px;
  list-style: none;
}

.footer-contact ul li a {
  color: var(--font-black);
  font-size: var(--font-size-m);
  text-decoration: none;
}

.footer-contact ul li a img {
  width: 16px;
  margin-right: 10px;
}

.footer-about {
  width: 320px;
  font-size: var(--font-size-m);
  padding: 30px 0 10px;
  line-height: 28px;
}

.footer-star {
  font-size: var(--font-size-s);
}

.social-icon {
  margin: 5px 0;
}

.social-icon ul {
  margin: 0;
  padding: 0;
  display: flex;
}

.social-icon ul li {
  margin-right: 10px;
  list-style: none;
}

.social-icon ul li img {
  width: 25px;
}

.copyright {
  text-align: center;
  font-size: var(--font-size-l);
  color: var(--font-black);
  background: var(--bg-light-grey3);
  margin-top: 50px;
  padding: 20px 0;
}

@media screen and (min-width: 100px) and (max-width: 840px) {
  .footer-header {
    font-size: var(--font-size-s);
    padding-bottom: 10px;
  }

  .footer-address ul li, .footer-menu ul li a {
    font-size: var(--font-size-xs);
  }

  .footer-inner {
    width: calc(100% - 80px);
    flex-flow: wrap;
  }

  .footer-inner .footer-col.first-col {
    width: 100%;
    flex: none;
    padding-bottom: 10px;
  }

  .footer-contact ul li a {
    font-size: var(--font-size-xs);
  }

  .footer-contact {
    padding-top: 5px;
  }

  .footer-inner .footer-col.second-col {
    width: 33%;
    flex: none;
  }

  .footer-about {
    width: 100%;
    font-size: var(--font-size-xs);
    padding: 15px 0 10px;
    line-height: 20px;
  }

  .footer-col {
    width: 49%;
  }

  .copyright {
    font-size: var(--font-size-13);
    margin-top: 20px;
    padding: 10px 0;
  }

  .footer-inner {
    width: calc(100% - 40px);
    padding: 10px 20px;
  }
}

.account-info {
  background: linear-gradient(#ff6b011a 0%, #fff0 100%);
}

.account-info .account-details .detail-item {
  width: calc(50% - 10px);
}

.multi-select-wrapper .searchWrapper {
  min-height: 50px;
  border: var(--border-black-2);
  font-size: var(--font-size-l);
  background: var(--bg-white);
  color: var(--font-black);
  border-radius: 10px;
  margin-top: 5px;
  padding: 5px 50px 5px 10px;
  position: relative;
}

.multi-select-wrapper .searchWrapper input {
  width: 40%;
  padding: 10px 0;
}

.multi-select-wrapper .searchWrapper .chip {
  white-space: inherit;
  background: var(--bg-orange);
  padding: 10px;
  font-size: 16px;
}

.multi-select-wrapper .optionContainer .highlightOption, .multi-select-wrapper .optionContainer li:hover {
  background: var(--bg-orange);
}

.modal-root .doted-border {
  background-image: linear-gradient(to right, #2e2e2e 26%, #fff0 2%);
  background-position: bottom;
  background-repeat: repeat-x;
  background-size: 7px 1px;
  border: none;
  padding-bottom: 1px;
}

.doted-border .modal-header-text {
  color: var(--font-black);
}

.doted-border .modal-header-text img {
  margin-right: 0;
  top: 11px;
}

.order-datetime {
  font-size: var(--font-size-s);
  color: var(--font-grey);
  padding-top: 15px;
}

.order-detail {
  flex-wrap: wrap;
  padding: 20px 0;
  display: flex;
}

.order-detail .detail-item {
  width: calc(33% - 10px);
  padding: 20px 5px;
}

.order-detail .detail-item label {
  font-size: var(--font-size-s);
  color: var(--font-grey);
  margin-bottom: 10px;
  font-weight: normal;
  display: block;
  position: relative;
}

.order-detail .detail-item div {
  font-size: var(--font-size-l);
  color: var(--font-black);
  font-weight: var(--font-weight-700);
  padding-top: 5px;
}

.order-detail .order-info span:first-child {
  margin-right: 5px;
  position: relative;
  top: -6px;
}

.order-detail .order-info {
  display: flex;
}

.order-detail .order-info span:last-child {
  flex-direction: column;
  display: flex;
}

.order-detail .order-info .order-info-icon label:first-child {
  font-size: var(--font-size-l);
  color: var(--font-black);
  font-weight: var(--font-weight-700);
}

@media screen and (min-width: 100px) and (max-width: 676px) {
  .order-detail .detail-item label {
    font-size: var(--font-size-9);
    margin-bottom: 0;
  }

  .modal-body .account-details .detail-item label {
    font-size: var(--font-size-9);
    white-space: nowrap;
  }

  .order-detail .order-info span.order-info-icon {
    display: initial;
  }

  .order-detail .order-info .order-info-icon label:first-child, .order-detail .detail-item div {
    font-size: var(--font-size-s);
  }

  .modal-body .account-details .detail-item {
    width: calc(33% - 4px);
    padding: 20px 2px;
  }
}

.modal-root .doted-border {
  background-image: linear-gradient(to right, #2e2e2e 26%, #fff0 2%);
  background-position: bottom;
  background-repeat: repeat-x;
  background-size: 7px 1px;
  border: none;
  padding-bottom: 1px;
}

.doted-border .modal-header-text {
  color: var(--font-black);
}

.doted-border .modal-header-text img {
  margin-right: 0;
  top: 11px;
}

.order-datetime {
  font-size: var(--font-size-s);
  color: var(--font-grey);
  padding-top: 15px;
}

.order-detail {
  flex-wrap: wrap;
  padding: 20px 0;
  display: flex;
}

.order-detail .detail-item {
  width: calc(33% - 10px);
  padding: 20px 5px;
}

.order-detail .detail-item label {
  font-size: var(--font-size-s);
  color: var(--font-grey);
  margin-bottom: 10px;
  font-weight: normal;
  display: block;
  position: relative;
}

.order-detail .detail-item div {
  font-size: var(--font-size-l);
  color: var(--font-black);
  font-weight: var(--font-weight-700);
  padding-top: 5px;
}

.order-detail .order-info span:first-child {
  margin-right: 5px;
  position: relative;
  top: -6px;
}

.order-detail .order-info {
  display: flex;
}

.order-detail .order-info span:last-child {
  flex-direction: column;
  display: flex;
}

.order-detail .order-info .order-info-icon label:first-child {
  font-size: var(--font-size-l);
  color: var(--font-black);
  font-weight: var(--font-weight-700);
}

@media screen and (min-width: 100px) and (max-width: 676px) {
  .order-detail .detail-item label {
    font-size: var(--font-size-9);
    margin-bottom: 0;
  }

  .modal-body .account-details .detail-item label {
    font-size: var(--font-size-9);
    white-space: nowrap;
  }

  .order-detail .order-info span.order-info-icon {
    display: initial;
  }

  .order-detail .order-info .order-info-icon label:first-child, .order-detail .detail-item div {
    font-size: var(--font-size-s);
  }

  .modal-body .account-details .detail-item {
    width: calc(33% - 4px);
    padding: 20px 2px;
  }
}

.alpaca-error-model:before {
  content: "";
  width: 200px;
  height: 200px;
  z-index: -1;
  opacity: .8;
  background: url("question-bg.7d95b1b6.png") 0 0 / 100% 100%;
  position: absolute;
  top: 70px;
  right: 0;
}

.alpaca-error-info {
  color: var(--font-black);
  max-width: 80%;
}

.alpaca-error-info div {
  text-transform: capitalize;
  line-height: 25px;
}

.alpaca-error-footer {
  max-width: 65%;
  text-align: left;
  justify-content: space-between;
  padding-top: 30px;
  display: flex;
}

.alpaca-error-footer a {
  margin-left: 40px;
}

@media screen and (min-width: 100px) and (max-width: 676px) {
  .alpaca-error-model:before {
    opacity: .3;
  }

  .alpaca-error-footer, .alpaca-error-info {
    max-width: 100%;
  }
}

.trade-action {
  justify-content: space-between;
  padding: 20px;
  display: flex;
}

.trade-box-item {
  flex: 1;
  padding: 10px;
  position: relative;
}

.trade-icon img {
  top: 9px;
  left: 6px;
}

.symbol-type-selector {
  margin-top: 20px;
  padding: 0 25px;
  display: inline-block;
}

.symbol-type-selector select {
  height: 30px;
  width: 100px;
  border: var(--border-black-2);
  color: var(--font-black);
  background: var(--bg-white);
  border-radius: 10px;
}

.switcher-container {
  width: 200px;
}

.trade-box {
  align-items: center;
  padding: 10px 0 0;
  display: flex;
}

.trade-box-item label {
  font-size: var(--font-size-s);
  color: var(--font-grey2);
  white-space: nowrap;
  display: block;
}

.trade-box-item input[type="text"], .trade-box-item input[type="number"] {
  width: calc(100% - 80px);
  border: var(--border-grey-1);
  color: var(--font-black);
  height: 43px;
  font-size: var( --font-size-l);
  background: var(--bg-white);
  border-radius: 10px;
  outline: none;
  margin-top: 5px;
  padding: 5px 70px 5px 10px;
}

.trade-box-item input.error {
  border: var(--border-red-1);
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.trade-box-item span {
  color: var(--font-red);
  font-size: var(--font-size-xs);
  position: absolute;
  bottom: -5px;
  left: 20px;
}

.trade-box-item a {
  color: var(--font-grey2);
  font-weight: bold;
  text-decoration: underline;
  position: absolute;
  top: 51px;
  right: 30px;
}

.trade-box-item select {
  width: 100%;
  border: var(--border-grey-1);
  height: 54px;
  font-size: var( --font-size-l);
  color: var(--font-black);
  background: var(--bg-white);
  font-weight: var(--font-weight-600);
  border-radius: 10px;
  outline: none;
  margin-top: 5px;
  padding: 5px 10px;
}

.trade-action .mobile-menu-text, .trade-action .toggle-switcher {
  display: none;
}

.trade-action .toggle-switcher:checked + .toggle-switch {
  background: var(--bg-grey);
}

.trade-action .toggle-switcher:checked + .toggle-switch:before {
  background: var(--bg-white);
  animation-name: tradeswitch;
  animation-duration: .35s;
  animation-fill-mode: forwards;
}

.trade-action .toggle-switch {
  width: 65px;
  height: 30px;
  background: var(--bg-orange);
  border-radius: 100px;
  transition: all .35s ease-in;
  display: flex;
  position: relative;
}

.trade-action .toggle-switch:before {
  content: "";
  width: 20px;
  height: 20px;
  z-index: 10;
  background: var(--bg-white);
  z-index: 1;
  border-radius: 82px;
  transition: all .2s ease-in;
  animation-name: reverse;
  animation-duration: .2s;
  animation-fill-mode: forwards;
  position: absolute;
  top: 5px;
  left: 4px;
}

.trade-action .toggler {
  flex-direction: row-reverse;
  align-items: center;
  margin-top: 20px;
  padding: 10px;
  display: flex;
}

.trade-action .toggler .label {
  font-size: var(--font-size-xl);
  padding: 8px;
}

@keyframes tradeswitch {
  0% {
    left: 4px;
  }

  60% {
    width: 20px;
    left: 4px;
  }

  100% {
    width: 20px;
    left: 42px;
  }
}

@keyframes reverse {
  0% {
    width: 30px;
    left: 35px;
  }

  60% {
    width: 30px;
    left: 30px;
  }

  100% {
    left: 4px;
  }
}

.trade.tab {
  padding: 20px;
}

.trade.tab .nav {
  border-bottom: var(--border-grey-1);
  justify-content: flex-start;
  margin: 0 0 10px;
}

.trade.tab .outlet {
  border: none;
}

.trade.tab ul.nav li {
  font-size: var(--font-size-m);
  text-transform: capitalize;
  color: var(--font-grey2);
  font-family: var(--font-family-inter);
  width: 250px;
  background: none;
  border: none;
  flex: none;
  padding: 12px 0;
  font-weight: normal;
}

.trade.tab ul.nav li:before {
  content: "";
  width: 6px;
  height: 12px;
  opacity: .4;
  background: url("quick-tab-icon.caa90ed2.png");
  margin: 0 5px;
}

.trade.tab ul.nav li.active:before {
  opacity: 1;
}

.trade.tab ul.nav li.active:after {
  height: 2px;
  background: var(--bg-orange);
  content: "";
  width: 95%;
  position: absolute;
  bottom: 0;
}

.trade.tab ul.nav li.active {
  color: var(--font-black);
  top: 0;
}

@media screen and (min-width: 100px) and (max-width: 676px) {
  .trade-box-item select, .trade-box-item input[type="text"], .trade-box-item input[type="number"] {
    font-size: var( --font-size-s);
  }

  .trade.tab ul.nav li {
    width: 45%;
  }

  .trade-icon img {
    top: 4px;
  }

  .list-wrapper .list .order-datetime {
    font-size: var(--font-size-8);
  }

  #trade-chart, .trade-action, .trade.tab {
    padding: 10px 5px;
  }

  .trade-action {
    display: block;
  }

  .trade-action .toggler {
    margin-top: 0;
  }

  .switcher-container {
    width: 200px;
    margin: auto;
  }

  .trade .trade-box {
    display: block;
  }

  .trade .quick-trade-single-col {
    width: auto;
  }
}

.trade-info .stock-name {
  padding: 20px 0;
  display: flex;
}

.trade-info {
  font-family: var(--font-family-roboto);
}

.trade-info .stock-icon {
  margin-right: 5px;
}

.trade-info .stock-name span label:last-child {
  opacity: .4;
  font-size: var(--font-size-13);
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
}

.trade-info .stock-desc {
  border-top: 2px var(--bg-grey3) dotted;
  border-bottom: 2px var(--bg-grey3) dotted;
  font-size: var(--font-size-s);
  padding: 10px 5px;
  line-height: 25px;
}

.trade-info .view-less {
  max-width: 200ch;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  white-space: normal;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box !important;
}

.trade-info .link-text {
  font-size: var(--font-size-s);
}

.trade-info .stock-desc-head {
  opacity: .4;
}

.trade-info .stock-detail {
  border-bottom: 2px var(--bg-grey3) dotted;
  flex-wrap: wrap;
  padding: 5px 0;
  display: flex;
}

.trade-info .stock-detail div {
  width: calc(50% - 10px);
  font-size: var(--font-size-s);
  padding: 10px 5px;
}

.trade-info .stock-detail div label {
  opacity: .4;
}

.trade-info .stock-detail div span {
  text-transform: uppercase;
  line-height: 22px;
  display: block;
}

.trade-info .stock-contact {
  font-size: var(--font-size-s);
  width: 165px;
  padding: 15px 5px;
  line-height: 22px;
}

.algo-ads-container {
  background: url("algos-ads-bg.76195e1a.png") 0 0 / 100% 100%;
  padding: 40px 20px 30px 30px;
}

.algo-ads-inner {
  display: flex;
}

.algo-ads-info {
  color: var(--font-white);
  font-size: var(--font-size-26);
  line-height: 40px;
}

.algo-ads-info span {
  font-weight: var(--font-weight-700);
}

.algo-ads-info span a {
  color: var(--font-white);
  text-decoration: underline;
}

@media screen and (min-width: 100px) and (max-width: 1020px) {
  .algo-ads-inner div {
    text-align: center;
  }

  .algo-ads-inner div img {
    width: 100%;
    max-width: 346px;
    margin-top: 15px;
  }

  .algo-ads-info {
    font-size: var(--font-size-m);
    line-height: 30px;
  }

  .algo-ads-container {
    padding: 10px;
  }

  .algo-ads-info .margin-top-30 {
    margin-top: 10px;
  }
}

.quick-trage-info {
  position: absolute;
  top: 35px;
  right: -6px;
}

.quick-trade-single-col {
  width: 33.33%;
}

.trade-portfolio .list tr:not(.separator):nth-child(2n+1), .trade-portfolio .list tr:not(.separator):nth-child(2n) {
  background: none;
}

.trade-portfolio .list tr th img {
  display: none;
}

.order-buttons {
  display: flex;
}

.order-buttons .margin-left-auto {
  margin-left: auto;
}

.order-buttons .close-button {
  margin-left: 10px;
}

.confirm-modal-content {
  height: 50px;
  color: var(--font-black);
  font-size: var(--font-size-m);
  font-family: var(--font-family-roboto);
  padding: 15px 0;
}

.margin-10 {
  margin: 10px;
}

.news-img a img {
  border: var(--border-grey-1);
  height: 95px;
  width: 161px;
}

.border-dotted {
  min-height: 200px;
  width: calc(33% - 20px);
  border-bottom: 2px dotted #999898;
  border-right: 2px dotted #999898;
  padding: 10px 10px 40px;
}

.border-dotted:nth-child(3n) {
  border-right: none;
}

.insights-container .insight-search-box {
  max-width: 400px;
  padding: 20px 5px;
}

.insights-container .trade-action .search-box-item input[type="text"] {
  border: var(--border-grey-1);
  background: var(--bg-white);
  outline: none;
}

.insights-container.sm-tab .tab ul.nav li {
  min-width: 25%;
}

.insights-info {
  font-size: var(--font-size-xl);
  color: var(--font-black);
  font-family: var(--font-family-inter);
  padding: 20px 5px;
  line-height: 32px;
}

.flex-wrap-container {
  flex-wrap: wrap;
  padding: 0;
  display: flex;
}

.insights-container .tab .outlet {
  border: none;
  padding-top: 20px;
}

.insights-container .tab ul.nav {
  border-bottom: var(--border-black-1);
}

.fullscreen-container {
  font-family: var(--font-family-roboto);
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 5px 10px;
  display: flex;
}

.fullscreen-container span {
  font-size: var(--font-size-l);
  color: var(--font-black);
}

.gaph-here {
  height: 150px;
  background: #ccc;
}

.fullscreen-container img {
  width: 18px;
  cursor: pointer;
}

.fullscreen-icon img {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.insights-graph-info {
  line-height: 25px;
  font-size: var(--font-size-l);
  color: var(--font-black);
  font-weight: var(--font-weight-300);
  font-family: var(--font-family-inter);
  text-align: justify;
  padding: 10px 0;
}

.insights-container .tab ul.nav li:first-child img {
  width: 19px;
  position: relative;
  left: 5px;
}

.insights-container .border-dotted .link-text {
  color: var(--font-black);
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-300);
  display: inherit;
  margin-top: 10px;
}

.insights-list-info {
  display: flex;
}

.insights-list-col {
  flex: 1;
  padding-top: 20px;
}

.insights-list-col ul {
  flex-wrap: wrap;
  margin: 0 0 0 20px;
  padding: 0;
  display: flex;
}

.insights-list-col ul li {
  color: var(--font-black);
  width: calc(49% - 15px);
  padding-bottom: 15px;
  padding-right: 15px;
}

.news-item {
  margin: 15px 0 10px;
  padding: 10px 0;
  display: flex;
}

.news-info {
  margin-top: 5px;
  margin-left: 15px;
}

.news-info label {
  font-size: var(--font-size-s);
  color: var(--font-grey);
  display: block;
}

.news-info a {
  font-size: var(--font-size-m);
  color: var(--font-black);
  font-weight: var(--font-weight-600);
  padding-top: 10px;
  display: block;
}

.news-info-status {
  font-weight: var(--font-weight-600);
  margin-top: 10px;
  display: flex;
}

.news-info-status img {
  width: 29px;
  margin-right: 10px;
}

.news-info-status div {
  padding-top: 5px;
}

.header-dotted {
  background-image: linear-gradient(to right, #2e2e2e 26%, #fff0 2%);
  background-position: bottom;
  background-repeat: repeat-x;
  background-size: 7px 1px;
  border: none;
  padding-bottom: 7px;
}

.graph-here {
  min-height: 150px;
}

.news-large-img img {
  width: 100%;
}

.news-large {
  background-image: linear-gradient(to right, #2e2e2e 26%, #fff0 2%);
  background-position: bottom;
  background-repeat: repeat-x;
  background-size: 10px 1px;
  border: none;
  margin: 20px 0;
  padding-bottom: 30px;
}

.news-large .link-text {
  color: var(--font-orange);
  font-size: var(--font-size-m);
}

.news-large-img {
  margin-top: 20px;
}

.news-large-info {
  color: var(--font-black);
  margin-top: 15px;
  line-height: 23px;
}

.border-dotted2 .border-dotted {
  width: calc(49% - 20px);
  padding-top: 20px;
}

.border-dotted2 .border-dotted:nth-child(3n) {
  border-bottom: 2px dotted #999898;
  border-right: 2px dotted #999898;
}

.border-dotted2 .border-dotted:nth-child(2n) {
  border-right: none;
}

.news-small-container .card-header-v2 {
  border-bottom: var(--border-black-1);
  background: none;
  padding: 10px 0;
}

.news-small-container .card-header-v2 .card-tools-icon {
  width: 18px;
  position: relative;
  top: 2px;
}

.news-small-container .card-header-v2 .card-tools-text {
  font-size: var(--font-size-xs);
  color: var(--font-blue);
}

.insights-container .news-small-container .link-text {
  color: var(--font-orange);
  font-weight: var(--font-weight-600);
}

@media screen and (min-width: 677px) and (max-width: 1024px) {
  .border-dotted {
    width: calc(49% - 20px);
  }

  .border-dotted:nth-child(3n) {
    border-right: 2px dotted #ccc;
  }

  .border-dotted:nth-child(2n) {
    border-right: none;
  }
}

@media screen and (min-width: 100px) and (max-width: 676px) {
  .news-large-info {
    font-size: var(--font-size-xs);
  }

  .insights-container .tab ul.nav li:first-child img {
    width: 14px;
  }

  .fullscreen-container span, .insights-info {
    font-size: var(--font-size-m);
  }

  .insights-graph-info {
    font-size: var(--font-size-xs);
  }

  .flex-wrap-container {
    display: block;
  }

  .border-dotted2 .border-dotted:nth-child(3n) {
    border-bottom: none;
    border-right: none;
  }

  .border-dotted2 .border-dotted {
    width: 100%;
    padding-left: 0;
  }

  .insights-list-col ul {
    display: block;
  }

  .insights-list-col ul li {
    width: 100%;
  }

  .news-item {
    display: block;
  }

  .border-dotted {
    width: calc(99% - 20px);
    border-right: none;
  }

  .insights-list-info {
    display: block;
  }

  .border-dotted:nth-child(3n) {
    border-right: none;
  }

  .insights-container.sm-tab .tab ul.nav li {
    min-width: 30%;
  }

  .border-dotted:nth-child(2n) {
    border-right: none;
  }

  .news-info {
    margin-left: 0;
  }

  .news-info span {
    font-size: var(--font-size-xs);
  }

  .news-info label {
    font-size: var(--font-size-11);
    color: var(--font-grey);
    display: block;
  }

  .news-info-status div {
    font-size: var(--font-size-xs);
  }

  .news-info-status img {
    width: 23px;
    margin-right: 5px;
  }
}

.news-datetime-container {
  justify-content: space-between;
  padding: 20px 0 0;
  display: flex;
}

.news-datetime-container .card-tools img {
  width: 24px;
}

.news-datetime {
  font-size: var(--font-size-m);
}

.news-company-info {
  font-size: var(--font-size-m);
  font-family: var(--font-family-roboto);
}

.news-dialog-link a {
  margin-left: 10px;
}

.news-link-info {
  color: var(--font-orange);
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-600);
  outline: none;
}

.news-detail-item {
  margin-top: 30px;
}

.news-dialog-info {
  text-align: justify;
  line-height: 25px;
}

.like-share-container {
  justify-content: right;
  padding: 20px 10px;
  display: flex;
}

.like-share-container span {
  font-weight: var(--font-weight-600);
  margin-left: 20px;
}

.like-share-container span img {
  width: 20px;
  cursor: pointer;
  margin-left: 5px;
  position: relative;
  top: 4px;
}

.news-dialog-btn-container {
  margin-bottom: 20px;
  display: flex;
}

.news-dialog-btn-container a {
  color: var(--font-white);
  font-weight: var(--font-weight-600);
  background: var(--bg-dark-grey);
  border-radius: 10px;
  margin-right: 15px;
  padding: 10px 25px;
}

.news-dialog-btn-container a:hover {
  background: var(--bg-orange);
}

.modal-root.news-dialog {
  max-width: 980px;
}

.modal-root.news-dialog .modal-body {
  max-height: 84vh;
}

.news-dialog .news-detail-item img {
  width: 100%;
}

@media screen and (min-width: 100px) and (max-width: 676px) {
  .news-dialog-link {
    flex-wrap: wrap;
    display: flex;
  }

  .news-datetime {
    font-size: var(--font-size-xs);
  }

  .news-link-info {
    font-size: var(--font-size-11);
  }

  .news-company-info {
    font-size: var(--font-size-xs);
  }

  .news-datetime-container .card-tools img {
    width: 20px;
    position: relative;
    top: -2px;
  }

  .news-dialog-btn-container a {
    font-size: var(--font-size-11);
    margin-right: 5px;
    padding: 5px 10px;
  }
}

.metrics-detail-dialog {
  padding: 10px 0;
}

.metrics-detail-dialog .history-date label {
  font-size: var(--font-size-m);
  color: var(--font-black);
}

.metrics-detail-dialog .history-date img {
  position: relative;
  top: 4px;
}

.metrics-detail-dialog .auto-omplete-item {
  justify-content: space-between;
  padding: 10px 10px 20px 0;
  display: flex;
}

.metrics-detail-dialog .auto-omplete-info {
  align-items: center;
  display: flex;
}

.metrics-detail-dialog .auto-omplete-info span {
  color: var(--font-black);
  font-weight: var(--font-weight-600);
}

.metrics-detail-dialog .auto-omplete-info a {
  white-space: nowrap;
  margin-left: 15px;
  text-decoration: underline;
}

.metrics-detail-dialog .auto-omplete {
  position: relative;
}

.metrics-detail-dialog .auto-omplete input[type="text"] {
  width: calc(100% - 63px);
  border: var(--border-grey-1);
  height: 35px;
  font-size: var(--font-size-l);
  background: var(--bg-white);
  border-radius: 10px;
  outline: none;
  padding: 5px 50px 5px 10px;
}

.metrics-detail-dialog .auto-omplete img {
  width: 18px;
  position: absolute;
  top: 20px;
  right: 14px;
}

@media screen and (min-width: 100px) and (max-width: 676px) {
  .metrics-detail-dialog .auto-omplete-item {
    display: block;
  }

  .metrics-detail-dialog .auto-omplete-info span {
    font-size: var(--font-size-xs);
  }

  .metrics-detail-dialog .auto-omplete-info {
    padding-bottom: 10px;
  }

  .metrics-detail-dialog .history-date label {
    font-size: var(--font-size-xs);
  }

  .metrics-detail-dialog .history-date img {
    width: 18px;
  }
}

.dropdown-item {
  flex: 1;
  padding: 10px;
  position: relative;
}

.dropdown-item select {
  width: 33%;
  border: var(--border-grey-1);
  height: 54px;
  font-size: var( --font-size-l);
  color: var(--font-black);
  background: var(--bg-white);
  font-weight: var(--font-weight-600);
  border-radius: 10px;
  outline: none;
  margin-top: 5px;
  padding: 5px 10px;
}

.algo-editor-box {
  align-items: center;
  padding: 30px 0 0;
  display: flex;
}

.algo-editor-box-item {
  flex: 1;
  padding: 10px;
  position: relative;
}

.algo-editor-box-item label {
  font-size: var(--font-size-m);
  color: var(--font-black);
  white-space: nowrap;
  margin-bottom: 10px;
  display: block;
}

.card-title.edit-input-box input {
  border: var(--border-grey-1);
  height: 32px;
  border-radius: 5px;
  outline: none;
  margin-left: 10px;
}

.algo-editor-box-item input[type="text"], .algo-editor-box-item input[type="number"] {
  width: calc(100% - 80px);
  border: var(--border-grey-1);
  height: 43px;
  font-size: var(--font-size-l);
  border-radius: 10px;
  outline: none;
  margin-top: 5px;
  padding: 5px 70px 5px 10px;
}

.algo-editor-box-item input.error {
  border: var(--border-red-1);
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.algo-editor-box-item span {
  color: var(--font-red);
  font-size: var(--font-size-xs);
  position: absolute;
  bottom: -5px;
  left: 20px;
}

.algo-editor-box-item a {
  color: var(--font-grey2);
  font-weight: bold;
  text-decoration: underline;
  position: absolute;
  top: 51px;
  right: 30px;
}

.algo-editor-box-item select {
  width: 100%;
  border: var(--border-grey-1);
  background: var(--bg-white);
  color: var(--font-black);
  height: 54px;
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-600);
  border-radius: 10px;
  outline: none;
  margin-top: 5px;
  padding: 5px 10px;
}

.trade-action .search-box-item label {
  font-size: var(--font-size-s);
  color: var(--font-grey2);
  white-space: nowrap;
}

.algo-editor-action .search-box-item input[type="text"] {
  width: calc(100% - 63px);
  border: var(--border-black-2);
  height: 43px;
  font-size: var(--font-size-l);
  border-radius: 10px;
  margin-top: 5px;
  padding: 5px 50px 5px 10px;
}

.algo-edit-graph {
  padding: 30px 0;
}

.algo-edit-graph img {
  width: 100%;
}

.algo-edit-info {
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-m);
  text-align: center;
  padding: 15px 0;
}

.algo-edit-btn {
  justify-content: space-between;
  padding: 0 20px 30px;
  display: flex;
}

@media screen and (min-width: 100px) and (max-width: 676px) {
  .algo-editor-box {
    display: block;
  }

  .algo-edit-btn {
    padding: 0 0 30px;
  }
}

.slider-info {
  width: 280px;
  justify-content: space-between;
  padding: 10px 0;
  display: flex;
}

.slider-info span {
  color: var(--font-black);
  position: initial;
}

.tooltip .pophover {
  min-width: 20px;
  color: var(--font-white);
  text-align: center;
  font-size: var(--font-size-11);
  background: #2e2e2e;
  border: 2px solid #2e2e2e;
  padding: 5px;
  position: absolute;
  top: -30px;
}

.tooltip .pophover:after, .pophover:before {
  content: "";
  height: 0;
  width: 0;
  pointer-events: none;
  border: solid #0000;
  position: absolute;
  top: 100%;
  left: 50%;
}

.tooltip .pophover:before {
  border-width: 8px;
  border-color: #2e2e2e #c2e1f500 #c2e1f500;
  margin-left: -8px;
  left: 50%;
}

.slider {
  -webkit-appearance: none;
  width: 280px;
  height: 8px;
  opacity: .7;
  -webkit-transition: all .2s;
  background: #f37921;
  border-radius: 5px;
  outline: none;
  transition: opacity .2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  appearance: none;
  width: 18px;
  height: 18px;
  background: var(--bg-orange);
  cursor: pointer;
  border-radius: 100%;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  cursor: pointer;
  background: #04aa6d;
}

.signin-main {
  background-color: var(--font-white);
  width: 100%;
  height: 99vh;
  margin: auto;
  display: flex;
}

.signin-main .welcome-back {
  color: var(--bg-white);
  margin-bottom: 10px;
  font-weight: 700;
}

.signin-main .sign-text {
  color: var(--bg-white);
  font-weight: 500;
  line-height: 24px;
  font-size: var(--font-size-m);
}

.signup-link-txt {
  color: var(--font-blue);
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-m);
  padding: 0 30px;
}

.signin-main .sign-right-side {
  flex-direction: column;
  flex: 1;
  align-items: center;
  margin-top: 34px;
  display: flex;
}

.signin-main .sign-right-side form {
  text-align: center;
}

.signin-main .form-header {
  color: var(--log-cabin);
  text-align: center;
  font-weight: 800;
}

.signin-main .form-main-container {
  width: 300px;
  text-align: left;
  flex-direction: column;
  margin: auto;
  display: flex;
}

.signin-main .button-devider {
  justify-content: center;
  margin-top: 25px;
  display: flex;
}

.signin-main .button-devider-info {
  height: 24px;
  letter-spacing: 0;
  opacity: .6;
  text-align: center;
  white-space: nowrap;
  width: 22px;
  margin-left: 17px;
  line-height: 24px;
}

.signin-main .button-devider-bg {
  height: 1px;
  width: 136px;
  background: var(--bg-grey3);
  margin-top: 11.5px;
  margin-left: 17px;
}

.signin-main .button-container {
  height: 56px;
  width: 327px;
  border-radius: 46px;
  margin-top: 16px;
  margin-right: 30px;
  position: relative;
}

.signin-main .button-div {
  margin-top: 15px;
}

.signin-main .button-icons {
  width: 24px;
  position: relative;
  left: -10px;
}

.signin-main .button-blue {
  background: var(--bg-marino);
  height: 56px;
  font-family: var(--Roboto);
  width: 300px;
  border: var(--border-grey-1);
  cursor: pointer;
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-600);
  color: var(--font-white);
  border-radius: 55px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.signin-main .valign-text-middle {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.signin-main .button-orange {
  background-color: var(--mt-orange);
  height: 52px;
  width: 300px;
  font-family: var(--Roboto);
  font-weight: var(--font-weight-600);
  color: var(--font-white);
  font-size: var(--font-size-m);
  border: var(--border-grey-1);
  cursor: pointer;
  border-radius: 50px;
  align-items: center;
}

.signin-main .button-white {
  background-color: var(--bg-white);
  height: 56px;
  width: 300px;
  border: var(--border-grey-1);
  cursor: pointer;
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-600);
  color: var(--font-black);
  border-radius: 55px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.signin-main .button-icons {
  width: 24px;
  position: relative;
  left: -10px;
}

.signin-main .button-blck {
  background: var(--bg-black);
  height: 56px;
  width: 300px;
  border: var(--border-grey-1);
  cursor: pointer;
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-600);
  color: var(--bg-white);
  border-radius: 55px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.signin-main .header-large {
  font-family: var(--font-family-inter);
  font-size: var(--font-size-40);
  color: var(--font-white);
  letter-spacing: 0;
  font-style: normal;
  font-weight: 800;
}

.signin-main .form-control-text-box {
  height: 35px;
  background: #f8f8f8;
  border: 1px solid #edeaea;
  border-radius: 5px;
  outline: none;
  margin-top: 7px;
  padding: 5px;
}

.signin-main.w elcome-text {
  margin-top: 53px;
  margin-bottom: 15px;
}

.signin-main .form-group-container {
  flex-direction: column;
  padding-bottom: 15px;
  display: flex;
}

.signin-main .form-header {
  font-family: var(--font-family-inter);
  font-size: var(--font-size-36);
  letter-spacing: 0;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 800;
}

.signin-main .button-div-error {
  padding: 5px 10px;
}

span.error {
  color: var(--font-red);
}

@media screen and (min-width: 10px) and (max-width: 730px) {
  .signin-main .form-header {
    padding-bottom: 30px;
  }

  .signin-main {
    display: block;
  }
}

/*# sourceMappingURL=index.624ecf2b.css.map */
