.algo-ads-container {
	background: url(../../../static/images/algos-ads-bg.png);
	background-size: 100% 100%;
	padding: 40px 20px 30px 30px;
}

.algo-ads-inner {
	display: flex;
}

.algo-ads-info {
	color: var(--font-white);
	font-size: var(--font-size-26);
	line-height: 40px;
}

.algo-ads-info span {
	font-weight: var(--font-weight-700);
}

.algo-ads-info span a {
	color: var(--font-white);
	text-decoration: underline;
}

@media screen and (min-width: 100px) and (max-width: 1020px) {
	.algo-ads-inner div {
		text-align: center;
	}
	.algo-ads-inner div img {
		margin-top: 15px;
		width: 100%;
		max-width: 346px;
	}
	.algo-ads-info {
		font-size: var(--font-size-m);
		line-height: 30px;
	}
	.algo-ads-container {
		padding: 10px;
	}
	.algo-ads-info .margin-top-30 {
		margin-top: 10px;
	}
}